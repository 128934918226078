import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/es';
import tz from 'moment-timezone';
import Button from '@material-ui/core/Button';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import Styles from "../components/Styles";


var logoUSFLight = "https://unionsinfronteras.com/media/logoUSF-light.png";

var facebookIcon = "https://unionsinfronteras.com/media/Facebook.png";

function time24to12(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
};

const getRealTime = (minutes) => {
    if(minutes < 10)
        return '0' + minutes
    else
        return minutes
}


const formatTextBold = (props) => {
    //console.log('TEXTO A CONVERTIR EN BOLD', props.text)

    //Divide el texto en secciones individuales
    var startBold = props.text.split('(snn)')
    if(startBold.length > 1){
        var endBold = []
        //console.log(`Pos salió esto:\n${JSON.stringify(startBold)}\n${startBold.length}`)
        for (var i = 0; i < startBold.length; i++) {
            endBold.push(
                startBold[i].split('(enn)')
            )
        }
        //console.log(`Pos LUEGO salió esto:\n${JSON.stringify(endBold)}\n${startBold.length}`)
        for (var y = 0; y < endBold.length; y++) {
            if(endBold[y].length > 1){
                //console.log(`Pos vamos a editar esto:\n${JSON.stringify(endBold[y])}\n${endBold[y].length}`)
                endBold[y][0] = (<span key={Math.random(0, 10000000)} style={{fontWeight: 'bold',}}>{endBold[y][0]}</span>)
            }
        }
        return endBold
    }else{
        return props.text
    }
}
const formatingText = (props) => {

    //Divide el texto en secciones individuales
    var startItalic = props.text.split('(skk)')
    //Se genera un array con las divisiones, si es mayor a 1 hacemos algo
    if(startItalic.length > 1){
        var endItalic = []
        //console.log(`Pos salió esto:\n${JSON.stringify(startItalic)}\n${startItalic.length}`)
        //Ahora vamos a dividir las secciones del array para separar la terminación del formato
        for (var i = 0; i < startItalic.length; i++) {
            //Agregamos arrays dentro del array creando una matriz
            endItalic.push(
                //Se genera algo como [["Sin (ekk)"], ["Este sí tiene (ekk)", "Esto fue lo que sobró"], ["Sin (ekk)"]]
                startItalic[i].split('(ekk)')
            )
        }
        //console.log(`Pos LUEGO salió esto:\n${JSON.stringify(endItalic)}\n${startItalic.length}`)
        for (var y = 0; y < endItalic.length; y++) {
            //Si el array de la posición y en la matriz tiene más de un elemento entonces hace algo
            if(endItalic[y].length > 1){
                //console.log(`Pos vamos a editar esto:\n${JSON.stringify(endItalic[y])}\n${endItalic[y].length}`)
                //Reemplazamos el primer elemento del array por el componente con formato
                endItalic[y][0] = (<span key={Math.random(0, 10000000)} style={{fontStyle: 'italic', fontWeight: 'normal'}}>{formatTextBold({text: endItalic[y][0]})}</span>)
                endItalic[y][1] = (<span key={Math.random(0, 10000000)}>{formatTextBold({text: endItalic[y][1]})}</span>)
            }else{
                endItalic[y][0] = (<span key={Math.random(0, 10000000)}>{formatTextBold({text: endItalic[y][0]})}</span>)
            }
        }
        return endItalic
    }else{
        return formatTextBold({text: props.text})
    }

}

const getDayDate = (day) => {
    switch(day.toLowerCase()){
        case 'lunes':
            return '2020-12-07'
        case 'martes':
            return '2020-12-08'
        case 'miercoles':
            return '2020-12-09'
        case 'jueves':
            return '2020-12-10'
        case 'viernes':
            return '2020-12-11'
        case 'sabado':
            return '2020-12-12'
        case 'domingo':
            return '2020-12-13'
        default:
            return '2020-12-07'
    }
}

const getDayDatePro = (daySent) => {
    var startOfWeek = moment().startOf('week');
    var endOfWeek = moment().endOf('week');

    var days = [];
    var day = startOfWeek;

    while (day <= endOfWeek) {
        days.push(day);
        day = day.clone().add(1, 'd');
    }
    switch(daySent.toLowerCase()){
        case 'lunes':
            return days[0].format('YYYY-MM-DD')
        case 'martes':
            return days[1].format('YYYY-MM-DD')
        case 'miercoles':
            return days[2].format('YYYY-MM-DD')
        case 'jueves':
            return days[3].format('YYYY-MM-DD')
        case 'viernes':
            return days[4].format('YYYY-MM-DD')
        case 'sabado':
            return days[5].format('YYYY-MM-DD')
        case 'domingo':
            return days[6].format('YYYY-MM-DD')
        default:
            return days[0].format('YYYY-MM-DD')
    }
}

const showDaysHour = (type, days, hour) => {
    var finalDays = '| ';
    var finalHour = '';

    var horaVenezuela = ''
    var horaLocal = ''

    var today
    var weekday=new Array(7)
    weekday[0] = 'Domingo'
    weekday[1] = 'Lunes'
    weekday[2] = 'Martes'
    weekday[3] = 'Miércoles'
    weekday[4] = 'Jueves'
    weekday[5] = 'Viernes'
    weekday[6] = 'Sábado'



    for (var i = 0; i < days.length; i++) {
        //HORARIO VERANO -5:00 HORARIO INVIERNO -4:00
        //console.log('FECHAS: ' + getDayDatePro(days[i]))
        //today = new Date(getDayDate(days[i]) + 'T' + hour + '-05:00')
        today = moment.tz(getDayDatePro(days[i]) + ' ' + hour, 'America/Caracas')
        //today = moment.tz('2021-07-08 14:00', 'America/Caracas')
        const localtz = moment.tz.guess()
        var finalToday = today.clone().tz(localtz)
        //console.log('FECHA HOY: ' + today.format('YYYY-MM-DD h:mm a') + '\n\n' + finalToday.format('YYYY-MM-DD h:mm a'))
        if(i != days.length-1)
            finalDays += finalToday.format('dddd')[0].toUpperCase() + finalToday.format('dddd').slice(1)  + ' | '
        else
            finalDays += finalToday.format('dddd')[0].toUpperCase() + finalToday.format('dddd').slice(1)  + ' |'

        
    }

    
    //finalHour = time24to12(getRealTime(today.getHours()) + ':' + getRealTime(today.getMinutes()))
    finalHour = finalToday.format('h:mm a')


    //console.log(`HV: ${horaVenezuela} HL: ${horaLocal}\nFD: ${finalDays} FH: ${finalHour}`)
    if(type == 'day'){
        if(days[0] == 'Escríbenos a:')
            return days[0]
        else
            return finalDays
    }else{
        if(days[0] == 'Escríbenos a:')
            return hour
        else
            return finalHour
    }
}

function ProgramItem(props) {
    const { contactItemContainerMini, contactIcon, contactDetailsContainer, contactSubtitle, contactArrowLabel, speakersIconBigStyle, wzDetailsHourProgram, wzDetailsDayProgram, programTitle, speakersIconBigContainer, headerContainer, facebookMini, facebookContainer, paperCounter, paperTitle, paperHourDay, paperDescription, wzSubtitleFading, wzDescriptionProgram, wzDetailsProgram, playIconContainer, playIconStyle, playerContainer, welcomeMessages, paper, logoHeader, logoHeaderContainer, wzSubtitle, wzTitle, titleHeaderContainer } = Styles();

    return (
        /*Pos aquí va el contenido*/
        <Grid key={props.key} container item xs={12}>
            <Grid item xs={4} className={speakersIconBigContainer}>
                <img className={speakersIconBigStyle} src={props.item.icon} alt="Logo"/>
            </Grid>
            <Grid item xs={8}>
                <Grid container style={{justifyContent: 'center'}} item xs={12}>
                    <Paper className={paperTitle}>
                        <p className={programTitle} style={{opacity: 1, marginTop: 0, marginBottom: 0,}}>{props.item.title}</p>
                    </Paper>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12} sm={7} md={6}>
                        <Paper className={paperHourDay} style={{wordWrap: 'anywhere',}}>
                            <p className={wzDetailsDayProgram} style={{opacity: 1}}>{showDaysHour('day', props.item.day, props.item.hour)}</p>
                            <p className={wzDetailsHourProgram} style={{opacity: 1, fontWeight: '500', fontStyle: 'italic', color: 'rgba(3,52,75,1)'}}>{showDaysHour('hour', props.item.day, props.item.hour)}</p>
                            {props.item.nameVoice.map( (item, i) =>
                                {
                                    if(item.facebook != ''){
                                        return(
                                            <Button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(item.facebook, '_blank');
                                                }}
                                                className={contactItemContainerMini}
                                            >
                                                <img className={facebookMini} src={facebookIcon} alt="Logo"/>
                                                <Grid container style={{display: 'flex', background: ''}} className={contactDetailsContainer}>
                                                    <Grid item xs={11} style={{wordWrap: 'anywhere'}}>
                                                        <p className={wzDetailsProgram} style={{ opacity: 1, textAlign: 'left', fontWeight: 'normal'}}>{item.name}</p>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        )
                                    }else{
                                        return(
                                            <Button className={contactItemContainerMini}>
                                                <Grid container style={{display: 'flex', background: ''}} className={contactDetailsContainer}>
                                                    <Grid item xs={11} style={{wordWrap: 'anywhere'}}>
                                                        <p className={wzDetailsProgram} style={{ opacity: 1, textAlign: 'left', fontWeight: 'normal'}}>{item.name}</p>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        )
                                    }
                                }
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={5} md={6}>
                        <Paper className={paperDescription}>
                            <p className={wzDescriptionProgram} style={{opacity: 1}}>{formatingText({text: props.item.description})}</p>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ProgramItem;