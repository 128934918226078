import React, { Component } from "react";
import {
    HashRouter as Router,
    //BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import Container from '@material-ui/core/Container';

import Home from "./screens/Home";
import AboutUs from "./screens/AboutUs";
import Podcasts from "./screens/Podcasts";
import Styles from "./components/Styles";

function App() {

    const { headerContainer, logoHeader, logoHeaderContainer, allContainer } = Styles();

    return (
        <div className={allContainer}>
            <Router history={Router}>
                <div>
                    {/*
                        A <Switch> looks through all its children <Route>
                        elements and renders the first one whose path
                        matches the current URL. Use a <Switch> any time
                        you have multiple routes, but you want only one
                        of them to render at a time
                    */}
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/Nosotros">
                            <AboutUs />
                        </Route>
                        <Route path="/Podcasts">
                            <Podcasts />
                        </Route>
                    </Switch>
                </div>
            </Router>
        </div>
    );
}

export default App;