import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

import { createMuiTheme, responsiveFontSizes, ThemeProvider, makeStyles } from '@material-ui/core/styles';

import Wave from 'react-wavify';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import Styles from "../components/Styles";
//import DecimoAniversario from "../assets/img/DecimoAniversario.png";
//import LogoOn from "../assets/img/LogoOn.png";
//import MicUp from "../assets/img/MicUp.png";
//import Name from "../assets/img/Name.png";
//import Name2 from "../assets/img/Name2.png";
//import BgWaves from "../assets/img/BgWaves.png";


var logoUSFLight = "https://unionsinfronteras.com/media/logoUSF-light.png";
var LogoOff = "https://unionsinfronteras.com/media/Logo11A.png";
var LogoOn = "https://unionsinfronteras.com/media/LogoOn.png";
var MicUp = "https://unionsinfronteras.com/media/MicUp.png";
var Name = "https://unionsinfronteras.com/media/Name.png";
var DecimoAniversario = "https://unionsinfronteras.com/media/microphone.png";
var BgWaves = "https://unionsinfronteras.com/media/BgWaves.png";
var Name2 = "https://unionsinfronteras.com/media/Name2.png";

function fading(value, setOpacity) {
    var opacity = 0;
    var intervalID = setInterval(function() {
        if (opacity < 1) {
            opacity = opacity + 0.1
            setOpacity(opacity)
        } else if(opacity > 0){
            opacity = opacity - 0.1
            setOpacity(opacity)
        }else{
            clearInterval(intervalID);
        }
    }, 20);
}





function getBigWaveOptions(playing){
    if(playing){
        return {
            height: 15,
            amplitude: 10,
            speed: 1.3,
            points: 5
        }
    }else{
        return {
            height: 15,
            amplitude: 10,
            speed: 0.7,
            points: 1
        }
    }
}


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    //marginRight: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0
  },
  title: {
    flexGrow: 1,
  },
}));

function Header(props) {
    const classes = useStyles();
    const { waveFormTop, nameBg, nameBg2, micUpContainer, nameStyle, headerContainer, playIconStyle3, logoHeader, logoHeaderContainer, wzSubtitle, wzTitle, titleHeaderContainer } = Styles();

    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        //fading(opacity, setOpacity)
    }, [])

    return (
        /*<Grid container className={headerContainer} style={{position: 'relative', background: props.bg }}>
            <Wave
                fill="url(#gradient4)"
                paused={false}
                options={
                    {
                        height: 15,
                        amplitude: 10,
                        speed: 0.7,
                        points: 1
                    }
                }
                className={waveFormTop}
            >
                <defs>
                    <linearGradient id="gradient4" gradientTransform="rotate(90)">
                        <stop offset="20%"  stopColor="rgba(6,152,218,0.2)" />
                        <stop offset="80%" stopColor="rgba(6,152,218,0.3)" />
                    </linearGradient>
                </defs>
            </Wave>
            <Grid className={micUpContainer} style={{left: 0}}>
                <img className={logoHeader} src={MicUp} alt="Logo"/>
            </Grid>
            <Grid className={micUpContainer} style={{right: 0}}>
                <img className={logoHeader} src={MicUp} alt="Logo"/>
            </Grid>
            <Grid className={micUpContainer} style={{right: 0, left: 0, textAlign: 'center'}}>
                <img className={nameStyle} src={Name} alt="Logo"/>
            </Grid>
            <Grid item xs={12} className={logoHeaderContainer}>
                <img className={logoHeader} src={LogoOff} alt="Logo"/>
            </Grid>
            <Grid item container className={titleHeaderContainer}>
                <Grid item xs={12}>
                    <p className={wzTitle}>¡10 AÑOS CON LO MEJOR PARA TI!</p>
                </Grid>
                <Grid item xs={12}>
                    <p className={wzSubtitle}><PeopleAltIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.6)'}}/> Una programación diseñada para toda la familia. <PeopleAltIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.6)'}}/></p>
                </Grid>
            </Grid>
        </Grid>*/
        
        <AppBar position="static">
            <Toolbar style={{background: props.bg}}>
                <div className={waveFormTop}>
                    <img src={BgWaves} style={{opacity: 0.5, width: '100%', objectFit: 'fill'}} alt="Logo"/>
                </div>
                <IconButton edge="start" style={{position: 'relative'}} className={classes.menuButton} color="inherit" aria-label="menu">
                    <img className={logoHeader} src={LogoOff} alt="Logo"/>
                    <div className={nameBg}></div>
                    <img className={logoHeader} style={{width: '6em', position: 'absolute', bottom: '-2em', left: '0em'}} src={Name} alt="Logo"/>
                </IconButton>
                <Grid item xs={12} style={{position: 'relative', textAlign: 'center'}}>
                    <p style={{zIndex: 20, position: 'relative'}} className={wzTitle}>Una Programación diseñada para toda la familia.</p>
                    <div style={{textAlign: 'center', position: 'absolute', top: '-2.5em', left: 0, right: 0,}}>
                        <img className={logoHeader} style={{width: '10em'}} src={Name2} alt="Logo"/>
                    </div>
                </Grid>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <img className={logoHeader} src={DecimoAniversario} alt="Logo"/>
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

export default Header;