import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import { Link, withRouter } from "react-router-dom";

import Swal from 'sweetalert2';

import Carousel from 'react-material-ui-carousel';

import Wave from 'react-wavify';


import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import GetAppIcon from '@material-ui/icons/GetApp';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';


import Styles from "../components/Styles";
import Header from "../components/Header";
import ProgramItem from "../components/ProgramItem";
//import "../components/WavesCSS.css";
//import SMIIcon from "../assets/img/smiIcon.png";

var AllTogether = "https://unionsinfronteras.com/media/AllTogether.jpg";
var playIcon = "https://unionsinfronteras.com/media/playIcon.png";
var pauseIcon = "https://unionsinfronteras.com/media/pauseIcon.png";

var FacebookIcon = "https://unionsinfronteras.com/media/Facebook.png";
var EmailIcon = "https://unionsinfronteras.com/media/Email.png";
var InstagramIcon = "https://unionsinfronteras.com/media/Instagram.png";
var YouTubeIcon = "https://unionsinfronteras.com/media/YouTube.png";
var WebIcon = "https://unionsinfronteras.com/media/web-circle.png";
var SMIIcon = "https://unionsinfronteras.com/media/smiIcon.png";

function ShowOnScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({ target: window ? window() : undefined });

	return (
		<Slide appear={false} direction="up" in={trigger}>
		  	{children}
		</Slide>
	);
}

const getRightResponse = (response) => {
    if(typeof response.errors === 'object'){
        var error = ''
        for (var result in response.errors) {
            error += `${response.errors[result]}\n\n`
        }

        return error
    }else{
        return response.message
    }
}

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const formatTextBold = (props) => {
    //console.log('TEXTO A CONVERTIR EN BOLD', props.text)

    //Divide el texto en secciones individuales
    var startBold = props.text.split('(snn)')
    if(startBold.length > 1){
        var endBold = []
        //console.log(`Pos salió esto:\n${JSON.stringify(startBold)}\n${startBold.length}`)
        for (var i = 0; i < startBold.length; i++) {
            endBold.push(
                startBold[i].split('(enn)')
            )
        }
        //console.log(`Pos LUEGO salió esto:\n${JSON.stringify(endBold)}\n${startBold.length}`)
        for (var y = 0; y < endBold.length; y++) {
            if(endBold[y].length > 1){
                //console.log(`Pos vamos a editar esto:\n${JSON.stringify(endBold[y])}\n${endBold[y].length}`)
                endBold[y][0] = (<span key={Math.random(0, 10000000)} style={{fontWeight: 'bold',}}>{endBold[y][0]}</span>)
            }
        }
        return endBold
    }else{
        return props.text
    }
}
const formatingText = (props) => {

    //Divide el texto en secciones individuales
    var startItalic = props.text.split('(skk)')
    //Se genera un array con las divisiones, si es mayor a 1 hacemos algo
    if(startItalic.length > 1){
        var endItalic = []
        //console.log(`Pos salió esto:\n${JSON.stringify(startItalic)}\n${startItalic.length}`)
        //Ahora vamos a dividir las secciones del array para separar la terminación del formato
        for (var i = 0; i < startItalic.length; i++) {
            //Agregamos arrays dentro del array creando una matriz
            endItalic.push(
                //Se genera algo como [["Sin (ekk)"], ["Este sí tiene (ekk)", "Esto fue lo que sobró"], ["Sin (ekk)"]]
                startItalic[i].split('(ekk)')
            )
        }
        //console.log(`Pos LUEGO salió esto:\n${JSON.stringify(endItalic)}\n${startItalic.length}`)
        for (var y = 0; y < endItalic.length; y++) {
            //Si el array de la posición y en la matriz tiene más de un elemento entonces hace algo
            if(endItalic[y].length > 1){
                //console.log(`Pos vamos a editar esto:\n${JSON.stringify(endItalic[y])}\n${endItalic[y].length}`)
                //Reemplazamos el primer elemento del array por el componente con formato
                endItalic[y][0] = (<span key={Math.random(0, 10000000)} style={{fontStyle: 'italic', fontWeight: 'normal'}}>{formatTextBold({text: endItalic[y][0]})}</span>)
            }else{
                endItalic[y][0] = (<span key={Math.random(0, 10000000)}>{formatTextBold({text: endItalic[y][0]})}</span>)
            }
        }
        return endItalic
    }else{
        return formatTextBold({text: props.text})
    }
}

const getDataWelcomeMessage = (conection, setServerMessages, setRefreshing) => {
    //console.log(`Vamo a obtener lista de mensajes de bienvenida\n\n${conection}`)
    setRefreshing(true)
    fetch(`${conection}/bienvenida`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then(res => res.json())
    .catch(error => {
        //console.log(error)
		Swal.fire({
			icon: 'error',
			title: 'Unión Sin Fronteras',
			text: 'Error de conexión del servidor :(\n\nIntenta más tarde',
			confirmButtonText:
				'Aceptar',
		})
        setRefreshing(false)
    })
    .then(response => {
        //console.log(`LOG Mensajes de bienvenida: ${JSON.stringify(response)}`)
        if(response.errors == false){
            setServerMessages(response.data)
        }else{
			Swal.fire({
				icon: 'error',
				title: 'Unión Sin Fronteras',
				text: `${getRightResponse(response)}`,
				confirmButtonText:
					'Aceptar',
			})
        }
        setRefreshing(false)
    });
}

const getDataContactUs = (conection, setInfoItems, setRefreshing) => {
    //console.log(`Vamo a obtener lista de opciones de contacto\n\n${conection}`)
    setRefreshing(true)
    fetch(`${conection}/info`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
			icon: 'error',
			title: 'Unión Sin Fronteras',
			text: 'Error de conexión del servidor :(\n\nIntenta más tarde',
			confirmButtonText:
				'Aceptar',
		})
        setRefreshing(false)
    })
    .then(response => {
        //console.log(`LOG ContactUs: ${JSON.stringify(response)}`)
        if(response.errors == false){
            setInfoItems(response.data)
        }else{
            Swal.fire({
				icon: 'error',
				title: 'Unión Sin Fronteras',
				text: `${getRightResponse(response)}`,
				confirmButtonText:
					'Aceptar',
			})
        }
        setRefreshing(false)
    });
}


function AboutUs(props) {

	const conection = 'https://unionsinfronteras.com/usfa/public/api';

	const [welcomeMessages, setWelcomeMessages] = useState([
		{
			id: '1',
			title: 'Si estamos creciendo, siempre vamos a estar fuera de nuestra zona de confort. (skk)John Maxwell(ekk)'
		},
		{
			id: '2',
			title: 'Intenta, falla, piensa y vuelve a intentar. (skk)Alex Dey(ekk)'
		},
		{
			id: '3',
			title: 'La sinceridad hace a la menor de las personas más valiosa que el hipócrita más talentoso. (skk)Charles Spurgeon(ekk)'
		},
		{
			id: '4',
			title: 'Tengo tres perros peligrosos: la ingratitud, la soberbia y la envidia. Cuando muerden dejan una herida profunda. (skk)Martín Lutero(ekk)'
		},
		{
			id: '5',
			title: 'No puedo parar mientras existan vidas que salvar. (skk)Edith Cavell(ekk)'
		},
		{
			id: '6',
			title: 'Si has llegado a una situación difícil por seguir la voluntad de Dios no temas las consecuencias. (skk)Arnaldo Sánchez(ekk)'
		},
		{
			id: '7',
			title: 'Ama la verdad, vive la verdad, predica la verdad, defiende la verdad. Porque el que no habla la verdad, traiciona la verdad. (skk)Juan Huss(ekk)'
		},
		{
			id: '8',
			title: 'El tiempo es más valioso que el dinero, porque el tiempo es insustituible. (skk)John Maxwell(ekk)'
		},
		{
			id: '9',
			title: 'Cuando no podemos seguir soñando, morimos. (skk)Emma Goldman(ekk)'
		},
		{
			id: '10',
			title: 'Aunque el final del mundo sea mañana, hoy plantaré manzanos en mi huerto. (skk)Martín Lutero(ekk)'
		},
		{
			id: '11',
			title: 'No existen las distancias cuando se tiene un motivo. (skk)Jane Austen(ekk)'
		},
	]);


	const { waveFormRight, wzWaitingContianer, wzTextHuge, playIconStyle4, allContainerFilled, contactIcon, contactArrowLabel, contactSubtitle, contactTitle, contactDetailsContainer, contactItemContainer, footerIconStyle, realFooterContainer, podcastTitle, wzDetailsProgram, paperTitle, podcastSpeakers, podcastDate, podcastContainer, playIconPodcastContainer, paperPodcast, allTogetherIconBigStyle, playIconStyle2, allTogetherIconBigContainer, waveForm, gridPlayerContainer, hourPlayer, speakersPlayer, titlePlayer, allPlayerContainer, playerContainer, playIconContainer, playIconStyle, titleTextContainer, speakersIconStyle, speakersImageContainer, paper, paperMessage, footerContainer, carouselStyle, footer, footerBtn, popoverStyle, wzSubtitleFading, welcomeMessageContainer, wzText } = Styles();

	const [anchorElHome, setAnchorElHome] = useState(null);

	const handlePopoverOpenHome = (event) => {
		setAnchorElHome(event.currentTarget);
	};

	const handlePopoverCloseHome= () => {
		setAnchorElHome(null);
	};

	const openHome = Boolean(anchorElHome);

	const [anchorElAboutUs, setAnchorElAboutUs] = useState(null);

	const handlePopoverOpenAboutUs = (event) => {
		setAnchorElAboutUs(event.currentTarget);
	};

	const handlePopoverCloseAboutUs= () => {
		setAnchorElAboutUs(null);
	};

	const openAboutUs = Boolean(anchorElAboutUs);

	const [anchorElAndroidLink, setAnchorElAndroidLink] = useState(null);

	const handlePopoverOpenAndroidLink = (event) => {
		setAnchorElAndroidLink(event.currentTarget);
	};

	const handlePopoverCloseAndroidLink= () => {
		setAnchorElAndroidLink(null);
	};

	const openAndroidLink = Boolean(anchorElAndroidLink);

	const [anchorEliOSLink, setAnchorEliOSLink] = useState(null);

	const handlePopoverOpeniOSLink = (event) => {
		setAnchorEliOSLink(event.currentTarget);
	};

	const handlePopoverCloseiOSLink= () => {
		setAnchorEliOSLink(null);
	};

	const openiOSLink = Boolean(anchorEliOSLink);

	

	const [anchorElSMI, setAnchorElSMI] = useState(null);

	const handlePopoverOpenSMI = (event) => {
	    setAnchorElSMI(event.currentTarget);
	};

	const handlePopoverCloseSMI= () => {
	    setAnchorElSMI(null);
	};

	const openSMI = Boolean(anchorElSMI);



	const [infoItems, setInfoItems] = useState(
	    [
	        {
	            title: 'Unión Sin Fronteras Radio',
	            subtitle: 'unionsinfronteras.com',
	            link: 'https://www.unionsinfronteras.com/',
	            icon: WebIcon,
	            color: 'rgba(140, 200, 50, ',
	        },
	        {
	            title: 'Unión Sin Fronteras Radio',
	            subtitle: 'en Facebook',
	            link: 'https://www.facebook.com/FundacionUnionSinFronteras/',
	            icon: FacebookIcon,
	            color: 'rgba(15, 113, 174, ',
	        },
	        {
	            title: 'Unión Sin Fronteras Radio',
	            subtitle: 'en Instagram',
	            link: 'https://www.instagram.com/unionsinfronterasradio',
	            icon: InstagramIcon,
	            color: 'rgba(245, 183, 78, ',
	        },
	        {
	            title: 'Unión Sin Fronteras Radio',
	            subtitle: 'en YouTube',
	            link: 'https://www.youtube.com/channel/UCAtD_fPq4mOFjjpchG2YkwQ',
	            icon: YouTubeIcon,
	            color: 'rgba(225, 78, 66, ',
	        },
	        {
	            title: 'Escríbenos por email a',
	            subtitle: 'unionsinfronteras',
	            link: 'mailto:unionsinfronteras@unionsinfronteras.com',
	            icon: EmailIcon,
	            color: 'rgba(251, 188, 5, ',
	        },
	    ]
	);

	const [serverWelcomeMessageItems, setServerWelcomeMessageItems] = useState([]);

	const [refreshing, setRefreshing] = useState(false);

	const [textAreaValue, setTextAreaValue] = useState('')
	const textAreaRef = useRef(null);

	useEffect(() => {
		getDataContactUs(conection, setInfoItems, setRefreshing)
		getDataWelcomeMessage(conection, setServerWelcomeMessageItems, setRefreshing)
    }, [])

    const copyToClipboard = (textArea) => {
        textArea.current.select();
        document.execCommand('copy');
    };




  	return (
  		<Grid style={{ position: 'relative', background: 'linear-gradient(135deg, rgba(10, 29, 48, 1) 0%, rgba(3,52,75,1) 100%)',}}>
	    	<Wave
	    	    fill="url(#gradient2)"
	    	    paused={false}
	    	    options={
	    	    	{
	    	    		height: 8,
	    	    		amplitude: 10,
	    	    		speed: 1,
	    	    		points: 1
	    	    	}
	    	    }
	    	    className={waveFormRight}
	    	>
	    	    <defs>
	    	        <linearGradient id="gradient2" gradientTransform="rotate(180)">
	    	            <stop offset="20%"  stopColor="rgba(255, 255, 255, 0.11)" />
	    	            <stop offset="80%" stopColor="rgba(6, 152, 218, 0.5)" />
	    	        </linearGradient>
	    	    </defs>
	    	</Wave>
	    	<Header bg={'#EBF9FF'}/>
	    	<Container className={allContainerFilled}>
	    		<HideOnScroll {...props}>
		    		<Grid container spacing={2} className={welcomeMessageContainer}>
				        <Grid item xs={12}>
				          	<Paper className={paperMessage}>
				          		<Carousel
				          			className={carouselStyle}
				          			autoPlay={true}
				          			animation={'fade'}
				          			timeout={500}
				          			interval={6000}
				          			indicators={false}
				          			navButtonsAlwaysVisible={false}
				          			navButtonsAlwaysInvisible={true}
			          		        next={ (next, active) => {/*console.log(`we left ${active}, and are now at ${next}`)*/} }
			          		        prev={ (prev, active) => {/*console.log(`we left ${active}, and are now at ${prev}`)*/} }
			          		    >
			          		    	{serverWelcomeMessageItems.map( (item, i) => <p key={i} className={wzSubtitleFading} style={{opacity: 1}}><span style={{fontWeight: 'normal',}}>Palabra de hoy:</span> <RecordVoiceOverIcon fontSize="medium" style={{color: 'rgba(255, 255, 255, 0.6)'}}/> {formatingText({text: item.title})}</p> )}
			          		    </Carousel>
				          	</Paper>
				        </Grid>
				    </Grid>
				</HideOnScroll>
	            <Grid container className={realFooterContainer}>
	    	        <Grid item xs={12} sm={6} style={{paddingRight: '1em'}} className={allTogetherIconBigContainer}>
	    	        	<form>
	        	         	<textarea
	        	         		style={{display: 'none'}}
		        	           	ref={textAreaRef}
		        	           	value={textAreaValue}
	        	         	/>
	        	        </form>
	    	        	<p className={wzDetailsProgram}>VISÍTANOS</p>
	    	        	{infoItems.map( (item, i) => {
	    	        		return(
	    	        			<Button
	    	        				key={i}
	    	        			    onClick={() => {

	    	        			    	if(item.link == 'mailto:unionsinfronteras@unionsinfronteras.com'){
	    	        			    		var finalLink = ''
	    	        			    		for (var i = 7; i < item.link.length; i++) {
	    	        			    			finalLink += item.link[i]
	    	        			    		}
	    	        			    		setTextAreaValue(finalLink)
    			                    	  	Swal.fire({
    		    	                    	    icon: 'info',
    		    	                    	    title: 'Unión Sin Fronteras Radio',
    		    	                    	    text: `¿Gustas copiar el email o escribir al email en la aplicación de correo de tu dispositivo?`,
    		    	                    	    confirmButtonText:
    			                    	          	'Copiar',
    			                    	        cancelButtonText:
    			                    	        	'Ir a aplicación',
    			                    	        showCancelButton: true,
    			                    	        focusConfirm: true,
    			                    	        allowOutsideClick: false,
    			                    	        allowEscapeKey: false
    			                    	  	}).then((result) => {
							                    if (result.isConfirmed) {
							                    	copyToClipboard(textAreaRef)
							                    }else{
							                    	window.open(item.link, "_blank")
							                    }
							                })
	    	        			    	}else{
	    	        			    		window.open(item.link, "_blank")
	    	        			    	}
	    	        			        /*if(item.subtitle != 'unionsinfronteras@unionsinfronteras.com'){
	    	        			            Linking.canOpenURL(item.link).then(supported => {
	    	        			                if (supported) {
	    	        			                    window.open(item.link, "_blank")
	    	        			                } else {
	                	                    	  	Swal.fire({
	                    	                    	    icon: 'info',
	                    	                    	    title: 'Unión Sin Fronteras Radio',
	                    	                    	    text: `\nLos sentimos por el momento no estamos disponibles ${item.subtitle}`,
	                    	                    	    confirmButtonText:
	                	                    	          	'Aceptar',
	                	                    	  	})
	    	        			                }
	    	        			            })
	    	        			        }else
	    	        			            window.open(item.link, "_blank")
	    	        			        */
	    	        			    }}
	    	        			    className={contactItemContainer}
	    	        			    style={
	    	        			    	{
	    	        			    		borderColor: item.color + '1)',
	    	        			    		width: '100%',
	    	        			    		borderStyle: 'solid',
	    	        			    		background: `linear-gradient(90deg, ${item.color}0.4) 0%, ${item.color}0) 100%)`,
	    	        			    	}
	    	        			    }
	    	        			>
		        			        <img className={contactIcon} src={item.icon} alt="Logo"/>
		        			        <Grid container style={{display: 'flex', background: ''}} className={contactDetailsContainer}>
		        			            <Grid item xs={11}>
		        			            	<Grid item xs={12}>
		        			                	<p className={contactTitle}>{item.title}</p>
		        			            	</Grid>
	        			            		<Grid item xs={12}>
		        			                	<p className={contactSubtitle} style={{ color: item.color + '1)' }}>{item.subtitle}</p>
	        			            		</Grid>
		        			            </Grid>
		        			            <Grid item style={{background: ''}} xs={1}>
		        			            	<ArrowRightIcon className={contactArrowLabel} style={{ color: item.color + '1)' }}/>
		        			            </Grid>
		        			        </Grid>
		        				</Button>
	    	        		)
	    	        	})}
	    	        </Grid>
	    	        <Grid item xs={12} sm={6} className={allTogetherIconBigContainer}>
	    	        	<p className={wzDetailsProgram}>MISIÓN</p>
	    	        	<p className={wzDetailsProgram} style={{opacity: 1, fontWeight: 'normal', textAlign: 'justify', textJustify: 'inter-word',}}>Contribuir al bienestar de la sociedad; produciendo y transmitiendo mensajes variados y de alta calidad que fomenten su desarrollo integral y participación activa a través de radio, televisión, y medios electrónicos.</p>
	    	        	<p className={wzDetailsProgram} style={{marginTop: '2em'}}>VISIÓN</p>
	    	        	<p className={wzDetailsProgram} style={{opacity: 1, fontWeight: 'normal', textAlign: 'justify', textJustify: 'inter-word',}}>Consolidarnos como una productora audiovisual innovadora y con tecnología de punta, de gran alcance y audiencia, logrando un impacto social con espectro internacional a través de las diferentes plataformas de comunicación masivas como radio, televisión y medios electrónicos.</p>
	    	        	<p className={wzDetailsProgram} style={{marginTop: '2em'}}>NUESTRO OBJETIVO</p>
	    	        	<p className={wzDetailsProgram} style={{opacity: 1, fontWeight: 'normal', textAlign: 'justify', textJustify: 'inter-word',}}>Promover el desarrollo integral de la sociedad, consolidando su formación por medio de mensajes educativos, culturales y entretenimiento sano.</p>
	    	        	<p className={wzDetailsProgram} style={{marginTop: '2em'}}>+RADIOS</p>
	    	        	<a style={{'textDecoration': 'none'}} href="https://hosannaradiointernacional.com/" target="_blank"><p className={wzDetailsProgram} style={{opacity: 1, fontWeight: 'normal'}}>Hosanna International Radio (EE.UU.)</p></a>
	    	        	<a style={{'textDecoration': 'none'}} href="https://imaginaradio.net/" target="_blank"><p className={wzDetailsProgram} style={{opacity: 1, fontWeight: 'normal'}}>Imagina Radio (México)</p></a>
	    	        </Grid>
	    	        <Grid item xs={12}>
	    	          	<p className={wzDetailsProgram} style={{opacity: 1, fontWeight: 'normal', textAlign: 'center'}}>© 2010-2021 unionsinfronteras.com ¡Bendiciones!</p>
	    	        </Grid>
	            </Grid>
			    <Grid container>
			       	<Grid item xs={12} className={footerContainer}>
					    <BottomNavigation
					        className={footer}
				        >
		          			<Button
		          				className={footerBtn}
		          				variant="contained"
		          				onMouseEnter={handlePopoverOpeniOSLink}
		          				onMouseLeave={handlePopoverCloseiOSLink}
		          				onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://bit.ly/usfappios', "_blank")
                                }}
		          			>
		          				<AppleIcon className={footerIconStyle} style={{color: 'rgba(3,52,75,1)'}}/>
		          			</Button>
				        	<Popover
			        	        id="mouse-over-popover"
			        	        className={popoverStyle}
			        	        classes={{
			        	          	paper: paper,
			        	        }}
			        	        open={openiOSLink}
			        	        anchorEl={anchorEliOSLink}
			        	        anchorOrigin={{
			        	          	vertical: 'top',
			        	          	horizontal: 'center',
			        	        }}
			        	        transformOrigin={{
			        	          	vertical: 'bottom',
			        	          	horizontal: 'center',
			        	        }}
			        	        onClose={handlePopoverCloseiOSLink}
			        	        disableRestoreFocus
			        	    >
				        	    <Typography>Aplicación para iOS</Typography>
				        	</Popover>
		          			<Button
		          				className={footerBtn}
		          				variant="contained"
		          				onMouseEnter={handlePopoverOpenHome}
		          				onMouseLeave={handlePopoverCloseHome}
		          				onClick={() => {
		          					props.history.push({
		          					    pathname: "/",
		          					    //search: "?sort=name",
		          					    //hash: "#the-hash",
		          					    state: { 
		          					        userName: 'this.state.user',
		          					        //adminType: response.data
		          					    }
		          					});
		          				}}
		          			>
		          				<HomeIcon className={footerIconStyle} style={{color: 'rgba(255, 255, 255, 0.4)'}}/>
		          			</Button>
				        	<Popover
			        	        id="mouse-over-popover"
			        	        className={popoverStyle}
			        	        classes={{
			        	          	paper: paper,
			        	        }}
			        	        open={openHome}
			        	        anchorEl={anchorElHome}
			        	        anchorOrigin={{
			        	          	vertical: 'top',
			        	          	horizontal: 'center',
			        	        }}
			        	        transformOrigin={{
			        	          	vertical: 'bottom',
			        	          	horizontal: 'center',
			        	        }}
			        	        onClose={handlePopoverCloseHome}
			        	        disableRestoreFocus
			        	    >
				        	    <Typography>Inicio</Typography>
				        	</Popover>
				        	<Button
				        		className={footerBtn}
				        		variant="contained"
				        		onMouseEnter={handlePopoverOpenAboutUs}
				        		onMouseLeave={handlePopoverCloseAboutUs}
				        	>
				        		<PeopleAltIcon className={footerIconStyle} style={{color: 'rgba(255, 255, 255, 1)'}}/>
				        	</Button>
	    		        	<Popover
	    	        	        id="mouse-over-popover"
	    	        	        className={popoverStyle}
	    	        	        classes={{
	    	        	          	paper: paper,
	    	        	        }}
	    	        	        open={openAboutUs}
	    	        	        anchorEl={anchorElAboutUs}
	    	        	        anchorOrigin={{
	    	        	          	vertical: 'top',
	    	        	          	horizontal: 'center',
	    	        	        }}
	    	        	        transformOrigin={{
	    	        	          	vertical: 'bottom',
	    	        	          	horizontal: 'center',
	    	        	        }}
	    	        	        onClose={handlePopoverCloseAboutUs}
	    	        	        disableRestoreFocus
	    	        	    >
	    		        	    <Typography>Nosotros</Typography>
	    		        	</Popover>
	    		        	<Button
	    		        	    className={footerBtn}
	    		        	    variant="contained"
	    		        	    onMouseEnter={handlePopoverOpenSMI}
	    		        	    onMouseLeave={handlePopoverCloseSMI}
	    		        	    onClick={(e) => {
	    		        	        e.preventDefault();
	    		        	        window.open('https://asd1844.org.ve/', '_blank');
	    		        	    }}
	    		        	>
	    		        	    <img src={SMIIcon} className={playIconStyle4}/>
	    		        	</Button>
	    		        	<Popover
	    		        	    id="mouse-over-popover"
	    		        	    className={popoverStyle}
	    		        	    classes={{
	    		        	        paper: paper,
	    		        	    }}
	    		        	    open={openSMI}
	    		        	    anchorEl={anchorElSMI}
	    		        	    anchorOrigin={{
	    		        	        vertical: 'top',
	    		        	        horizontal: 'center',
	    		        	    }}
	    		        	    transformOrigin={{
	    		        	        vertical: 'bottom',
	    		        	        horizontal: 'center',
	    		        	    }}
	    		        	    onClose={handlePopoverCloseSMI}
	    		        	    disableRestoreFocus
	    		        	>
	    		        	    <Typography>SMI Venezuela</Typography>
	    		        	</Popover>
		          			<Button
		          				className={footerBtn}
		          				variant="contained"
		          				onMouseEnter={handlePopoverOpenAndroidLink}
		          				onMouseLeave={handlePopoverCloseAndroidLink}
		          				onClick={(e) => {
		          				    e.preventDefault();
		          				    window.open('https://bit.ly/usfappandroid', "_blank")
		          				}}
		          			>
		          				<AndroidIcon className={footerIconStyle} style={{color: 'rgba(3,52,75,1)'}}/>
		          			</Button>
				        	<Popover
			        	        id="mouse-over-popover"
			        	        className={popoverStyle}
			        	        classes={{
			        	          	paper: paper,
			        	        }}
			        	        open={openAndroidLink}
			        	        anchorEl={anchorElAndroidLink}
			        	        anchorOrigin={{
			        	          	vertical: 'top',
			        	          	horizontal: 'center',
			        	        }}
			        	        transformOrigin={{
			        	          	vertical: 'bottom',
			        	          	horizontal: 'center',
			        	        }}
			        	        onClose={handlePopoverCloseAndroidLink}
			        	        disableRestoreFocus
			        	    >
				        	    <Typography>Aplicación para Android</Typography>
				        	</Popover>
				        </BottomNavigation>
			       	</Grid>
			    </Grid>
	    	</Container>
			{
                refreshing ?
                    <Grid className={wzWaitingContianer}>
                        <p className={wzTextHuge} style={{color: 'white', fontWeight: 'bold', textAlign: 'center'}}>Cargando...</p>
                    </Grid>
                :
                    null
            }
  		</Grid>
  	);
}

export default withRouter(AboutUs);