import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { makeStyles } from '@material-ui/core/styles';

import { Link, withRouter } from "react-router-dom";

import Swal from 'sweetalert2';

import Carousel from 'react-material-ui-carousel';

import Wave from 'react-wavify';

import FlatList from 'flatlist-react';


import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import HomeIcon from '@material-ui/icons/Home';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import GetAppIcon from '@material-ui/icons/GetApp';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import SearchIcon from '@material-ui/icons/Search';


import Styles from "../components/Styles";
import Header from "../components/Header";
import ProgramItem from "../components/ProgramItem";
import "../components/WavesCSS.css";
//import podcastIcon from "../assets/img/podcastIcon.png";
//import SMIIcon from "../assets/img/smiIcon.png";

var BalsamoDeVida = "https://unionsinfronteras.com/media/BalsamoDeVida.jpg";
var JuventudActiva = "https://unionsinfronteras.com/media/JuventudActiva.jpg";
var EmpoderadaMujer = "https://unionsinfronteras.com/media/EmpoderadaMujer.jpg";
var UnaVezMas = "https://unionsinfronteras.com/media/UnaVezMas.jpg";
var VivenciaDigital = "https://unionsinfronteras.com/media/VivenciaDigital.jpg";
var LaBibliaATuAlcance = "https://unionsinfronteras.com/media/LaBibliaATuAlcance.jpg";
var QueAlguienMeExplique = "https://unionsinfronteras.com/media/QueAlguienMeExplique.jpg";
var UnEncuentroPersonalConDios = "https://unionsinfronteras.com/media/UnEncuentroPersonalConDios.jpg";
var UnRayoDeLuz = "https://unionsinfronteras.com/media/UnRayoDeLuz.jpg";
var SeguimosTrabajando = "https://unionsinfronteras.com/media/SeguimosTrabajando.jpg";

var AllTogether = "https://unionsinfronteras.com/media/AllTogether.jpg";
var playIcon = "https://unionsinfronteras.com/media/playIcon.png";
var pauseIcon = "https://unionsinfronteras.com/media/pauseIcon.png";
var podcastIcon = "https://unionsinfronteras.com/media/podcastIcon.png";
var SMIIcon = "https://unionsinfronteras.com/media/smiIcon.png";


const getRightResponse = (response) => {
    if(typeof response.errors === 'object'){
        var error = ''
        for (var result in response.errors) {
            error += `${response.errors[result]}\n\n`
        }

        return error
    }else{
        return response.message
    }
}


function ShowOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="up" in={trigger}>
            {children}
        </Slide>
    );
}

const useAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = (isPlaying) => setPlaying(isPlaying);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
};

function getWaveOptions(playing){
    if(playing){
        return {
            height: 8,
            amplitude: 8,
            speed: 0.9,
            points: 2
        }
    }else{
        return {
            height: 5,
            amplitude: 10,
            speed: 0.3,
            points: 2
        }
    }
}

function getBigWaveOptions(playing){
    if(playing){
        return {
            height: 4,
            amplitude: 12,
            speed: 0.9,
            points: 2
        }
    }else{
        return {
            height: 8,
            amplitude: 10,
            speed: 1,
            points: 1
        }
    }
}

function time24to12(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
};

const getDayDate = (day) => {
    switch(day.toLowerCase()){
        case 'lunes':
            return '2020-12-07'
        case 'martes':
            return '2020-12-08'
        case 'miércoles':
            return '2020-12-09'
        case 'jueves':
            return '2020-12-10'
        case 'viernes':
            return '2020-12-11'
        case 'sábado':
            return '2020-12-12'
        case 'domingo':
            return '2020-12-13'
        default:
            return '2020-12-07'
    }
}

const getRealTime = (minutes) => {
    if(minutes < 10)
        return '0' + minutes
    else
        return minutes
}

const showDaysHour = (type, days, hour) => {
    var finalDays = '| ';
    var finalHour = '';

    var horaVenezuela = ''
    var horaLocal = ''

    var today
    var weekday=new Array(7)
    weekday[0] = 'Domingo'
    weekday[1] = 'Lunes'
    weekday[2] = 'Martes'
    weekday[3] = 'Miércoles'
    weekday[4] = 'Jueves'
    weekday[5] = 'Viernes'
    weekday[6] = 'Sábado'

    for (var i = 0; i < days.length; i++) {
        today = new Date(getDayDate(days[i]) + 'T' + hour + ':00-04:00')
        if(i != days.length-1)
            finalDays += weekday[today.getDay()] + ' | '
        else
            finalDays += weekday[today.getDay()] + ' |'
    }

    finalHour = time24to12(getRealTime(today.getHours()) + ':' + getRealTime(today.getMinutes()))
    //var today = new Date(getDayDate(days)'T13:59:00Z')
    //var tomorrow = new Date(this.addDaysToDate(scheduleDate, 1, 'reverse')+'T13:59:00Z')


    //console.log(`HV: ${horaVenezuela} HL: ${horaLocal}\nFD: ${finalDays} FH: ${finalHour}`)
    if(type == 'day'){
        if(days[0] == 'Escríbenos a:')
            return days[0]
        else
            return finalDays
    }else{
        if(days[0] == 'Escríbenos a:')
            return '24 Horas al día'
        else
            return finalHour
    }
}

const formatTextBold = (props) => {
    //console.log('TEXTO A CONVERTIR EN BOLD', props.text)

    //Divide el texto en secciones individuales
    var startBold = props.text.split('(snn)')
    if(startBold.length > 1){
        var endBold = []
        //console.log(`Pos salió esto:\n${JSON.stringify(startBold)}\n${startBold.length}`)
        for (var i = 0; i < startBold.length; i++) {
            endBold.push(
                startBold[i].split('(enn)')
            )
        }
        //console.log(`Pos LUEGO salió esto:\n${JSON.stringify(endBold)}\n${startBold.length}`)
        for (var y = 0; y < endBold.length; y++) {
            if(endBold[y].length > 1){
                //console.log(`Pos vamos a editar esto:\n${JSON.stringify(endBold[y])}\n${endBold[y].length}`)
                endBold[y][0] = (<span key={Math.random(0, 10000000)} style={{fontWeight: 'bold',}}>{endBold[y][0]}</span>)
            }
        }
        return endBold
    }else{
        return props.text
    }
}
const formatingText = (props) => {

    //Divide el texto en secciones individuales
    var startItalic = props.text.split('(skk)')
    //Se genera un array con las divisiones, si es mayor a 1 hacemos algo
    if(startItalic.length > 1){
        var endItalic = []
        //console.log(`Pos salió esto:\n${JSON.stringify(startItalic)}\n${startItalic.length}`)
        //Ahora vamos a dividir las secciones del array para separar la terminación del formato
        for (var i = 0; i < startItalic.length; i++) {
            //Agregamos arrays dentro del array creando una matriz
            endItalic.push(
                //Se genera algo como [["Sin (ekk)"], ["Este sí tiene (ekk)", "Esto fue lo que sobró"], ["Sin (ekk)"]]
                startItalic[i].split('(ekk)')
            )
        }
        //console.log(`Pos LUEGO salió esto:\n${JSON.stringify(endItalic)}\n${startItalic.length}`)
        for (var y = 0; y < endItalic.length; y++) {
            //Si el array de la posición y en la matriz tiene más de un elemento entonces hace algo
            if(endItalic[y].length > 1){
                //console.log(`Pos vamos a editar esto:\n${JSON.stringify(endItalic[y])}\n${endItalic[y].length}`)
                //Reemplazamos el primer elemento del array por el componente con formato
                endItalic[y][0] = (<span key={Math.random(0, 10000000)} style={{fontStyle: 'italic', fontWeight: 'normal'}}>{formatTextBold({text: endItalic[y][0]})}</span>)
                endItalic[y][1] = (<span key={Math.random(0, 10000000)}>{formatTextBold({text: endItalic[y][1]})}</span>)
            }else{
                endItalic[y][0] = (<span key={Math.random(0, 10000000)}>{formatTextBold({text: endItalic[y][0]})}</span>)
            }
        }
        return endItalic
    }else{
        return formatTextBold({text: props.text})
    }
}

const getNameVoices = (nameVoices) => {
    var finalNames = '| '
    for (var i = 0; i < nameVoices.length; i++) {
        if(i != nameVoices.length-1)
            finalNames += nameVoices[i].name + ' | '
        else
            finalNames += nameVoices[i].name + ' |'
    }
    return finalNames
}

const useMultiAudio = urls => {
    const [sources] = useState(
        urls.map(url => {
            return {
                url,
                audio: new Audio(url),
            }
        }),
    )

    const [players, setPlayers] = useState(
        urls.map(url => {
            return {
                url,
                playing: false,
            }
        }),
    )

    const toggle = targetIndex => () => {
        const newPlayers = [...players]
        const currentIndex = players.findIndex(p => p.playing === true)
        if (currentIndex !== -1 && currentIndex !== targetIndex) {
            newPlayers[currentIndex].playing = false
            newPlayers[targetIndex].playing = true
        } else if (currentIndex !== -1) {
            newPlayers[targetIndex].playing = false
        } else {
            newPlayers[targetIndex].playing = true
        }
        setPlayers(newPlayers)
    }


    useEffect(() => {
        sources.forEach((source, i) => {
            players[i].playing ? source.audio.play() : source.audio.pause()
        })
    }, [sources, players])

    useEffect(() => {
        sources.forEach((source, i) => {
            source.audio.addEventListener('ended', () => {
                const newPlayers = [...players]
                newPlayers[i].playing = false
                setPlayers(newPlayers)
            })
        })
        return () => {
            sources.forEach((source, i) => {
                source.audio.removeEventListener('ended', () => {
                    const newPlayers = [...players]
                    newPlayers[i].playing = false
                    setPlayers(newPlayers)
                })
            })
        }
    }, [])

    return [players, toggle, sources]
}

const MultiPlayer = ({ urls, podcasts, currentTime, setCurrentTime, setPodcastsFiles }) => {
    const [players, toggle, sources] = useMultiAudio(urls)

    return (
        <FlatList
            list={players}
            renderItem={(item, idx) => (
                <Player
                    key={idx}
                    index={idx}
                    player={item}
                    source={sources[idx].audio}
                    podcast={podcasts[idx]}
                    podcastsFiles={podcasts}
                    setPodcastsFiles={setPodcastsFiles}
                    toggle={toggle(idx)}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                />
            )}
            renderWhenEmpty={() => <p className="align-middle text-center">No se encontraron resultados</p>}
        />
    )
}

const fancyTimeFormat = (time) =>{   
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}


var cont = 2
const Player = ({ index, player, toggle, podcast, podcastsFiles, setPodcastsFiles, source, currentTime, setCurrentTime }) => {
    const { sliderStyle, playIconStyle3, podcastContainer, sliderContainer, playIconPodcastContainer, playIconStyle2, podcastTitle, podcastSpeakers, podcastDate } = Styles();
    

    const actualSource = useRef(source);

    useEffect(() => {
        source.addEventListener("timeupdate", (e) => {
            // add code here to update the handle position
            setCurrentTime(e.target.currentTime)
        });
        source.addEventListener('loadedmetadata', (e) => {
            var finalPodcasts = []
            for (var i = 0; i < podcastsFiles.length; i++) {
                if(i == index){
                    finalPodcasts.push({
                        id: podcastsFiles[i].id,
                        title: podcastsFiles[i].title,
                        subtitle: podcastsFiles[i].subtitle,
                        date: podcastsFiles[i].date,
                        nameVoice: podcastsFiles[i].nameVoice,
                        url: podcastsFiles[i].url,
                        duration: e.target.duration
                    })
                }else{
                    finalPodcasts.push(podcastsFiles[i])
                }
            }
            setPodcastsFiles(finalPodcasts)

            
            //console.log('PODCASTS ' + JSON.stringify(finalPodcasts))
        });
        return () => {
            source.removeEventListener("timeupdate", () => {
                // add code here to update the handle position
                setCurrentTime(0)
            });
            source.removeEventListener('loadedmetadata', (e) => {
            });
        }
    }, [])
        

    //console.log('CURRENTIME: ' + currentTime)

    return(
        <Grid container xs={12} className={podcastContainer}>
            <Button onClick={() => {
                toggle()
                setCurrentTime(source.currentTime)
            }} className={playIconPodcastContainer}>
                {player.playing == true ? <img className={playIconStyle2} src={pauseIcon} alt="Logo"/> : <img className={playIconStyle2} src={playIcon} alt="Logo"/>}
                {/*<GetAppIcon style={{color: 'white'}} className={playIconStyle3}/>*/}
            </Button>
            <RecordVoiceOverIcon className={playIconStyle3} style={{color: 'rgba(255, 255, 255, 0.6)', marginLeft: '0.5em'}}/>
            
            <Grid item container xs={12} sm={9} md={10} style={{paddingLeft: '0.5em', marginBottom: 0, marginLeft: '0.5em', marginRight: '0.5em', justifyContent: 'space-between', alignItems: 'center'}}>
                <Grid item xs={5}>
                    <p className={podcastTitle}>{formatingText({text: podcast.title})}</p>
                    <p className={podcastSpeakers}>Locutores: {getNameVoices(podcast.nameVoice)}</p>
                </Grid>
                {
                    player.playing == true ?
                        <Grid item container xs={5} container>
                            <p className={podcastSpeakers}>{fancyTimeFormat(Math.floor(currentTime))}</p>
                            <Grid item className={sliderContainer} xs={8}>
                                <Slider
                                    defaultValue={0}
                                    step={1}
                                    min={0}
                                    max={source.duration}
                                    onChange={(event, value) => {
                                        setCurrentTime(value)
                                        //console.log('SLIDER: ' + JSON.stringify(value))
                                    }}
                                    onChangeCommitted={(event, value) => {
                                        source.currentTime = value
                                    }}
                                    value={currentTime}
                                    classes={sliderStyle}
                                />
                            </Grid>
                            {/*<p className={podcastSpeakers} style={{color: 'white'}}>{fancyTimeFormat(Math.floor(podcastsFiles[index].duration))}</p>*/}
                        </Grid>
                    : 
                        <Grid item container xs={5} container>
                            <p className={podcastSpeakers}>{fancyTimeFormat(Math.floor(source.currentTime))}</p>
                            <Grid item className={sliderContainer} xs={8}>
                                <Slider
                                    defaultValue={0}
                                    step={1}
                                    min={0}
                                    max={Math.floor(podcastsFiles[index].duration)}
                                    value={Math.floor(source.currentTime)}
                                />
                            </Grid>
                            {/*<p className={podcastSpeakers} style={{color: 'white'}}>{fancyTimeFormat(Math.floor(podcastsFiles[index].duration))}</p>*/}
                        </Grid>
                }
                
                <p className={podcastDate}>{formatingText({text: podcast.date})}</p>
            </Grid>
        </Grid>
    )
}

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const getTextInputStyle = (value, wzTextInputFilled, wzTextInput) => {
    if(value != '')
        return wzTextInputFilled
    else
        return wzTextInput
}

const getDataPodcast = (conection, setServerPodcasts, setRefreshing, setUrls, search, page, setPagination, setCurrentPage) => {
    //console.log(`Vamo a obtener lista de podcasts\n\n${conection}`)
    setRefreshing(true)
    fetch(`${conection}/podcast?filtro=${search}&perpage=15&page=${page}`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'error',
            title: 'Unión Sin Fronteras',
            text: 'Error de conexión del servidor :(\n\nIntenta más tarde',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing(false)
    })
    .then(response => {
        //console.log(`LOG Podcasts: ${JSON.stringify(response)}`)
        if(response.errors == false){
            setServerPodcasts(response.data)
            setPagination(response.meta.pagination)
            setCurrentPage(response.meta.pagination.current_page)

            var finalUrls = []
            for (var i = 0; i < response.data.length; i++) {
                finalUrls.push(response.data[i].url)
            }
            setUrls(finalUrls)
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Unión Sin Fronteras',
                text: `${getRightResponse(response)}`,
                confirmButtonText:
                    'Aceptar',
            })
        }
        setRefreshing(false)
    });
}

function Podcasts(props) {

    const conection = 'https://unionsinfronteras.com/usfa/public/api';


    const [podcastsFiles, setPodcastsFiles] = useState([
        {
            id: '1',
            title: '6 Pasos para triunfar',
            subtitle: 'JUVENTUD ACTIVA',
            date: '27-06-2019',
            nameVoice: [
                {
                    id: '1',
                    name: 'Keyla Pérez',
                },
            ],
            url: 'https://unionsinfronteras.com/p1.mp3',
            duration: 0
        },
        {
            id: '2',
            title: '¿Por qué te quejas tanto?',
            subtitle: 'QUE ALGUIÉN ME EXPLIQUE',
            date: '19-09-2019',
            nameVoice: [
                {
                    id: '1',
                    name: 'Ana Rojas',
                },
            ],
            url: 'https://unionsinfronteras.com/p2.mp3',
            duration: 0
        },
        {
            id: '3',
            title: 'Nanotecnología',
            subtitle: 'VIVENCIA DIGITAL',
            date: '04-11-2019',
            nameVoice: [
                {
                    id: '1',
                    name: 'Mileydis Soublette',
                },
            ],
            url: 'https://unionsinfronteras.com/p3.mp3',
            duration: 0
        },
        {
            id: '4',
            title: 'En el punto de quiebre',
            subtitle: 'UN LUGAR EN LA CIMA',
            date: '26-11-2019',
            nameVoice: [
                {
                    id: '1',
                    name: 'Arnaldo Sánchez',
                },
            ],
            url: 'https://unionsinfronteras.com/p4.mp3',
            duration: 0
        },
        {
            id: '5',
            title: 'Ante la incertidumbre',
            subtitle: 'UN LUGAR EN LA CIMA',
            date: '24-03-2020',
            nameVoice: [
                {
                    id: '1',
                    name: 'Arnaldo Sánchez',
                },
            ],
            url: 'https://unionsinfronteras.com/p5.mp3',
            duration: 0
        },
    ]);

    const [urls, setUrls] = useState([]);

    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    
    const [serverPodcastItems, setServerPodcastItems] = useState([]);

    const [refreshing, setRefreshing] = useState(false);


    useEffect(() => {
        getDataPodcast(conection, setServerPodcastItems, setRefreshing, setUrls, '', 1, setPagination, setCurrentPage)
    }, [])
    


    const { playIconStyle5, wzVerticalSpace1, wzWaitingContianer, wzTextInputLoginFilled, wzTextInputLogin, wzButtonLogin, wzButtonImageContainer, wzCardContainerRounded, wzTextHuge, wzTextNormal, speakersIconStyleContainer, waveFormRight, waveFormLeft, closeBtn, speakersIconContainer, playIconStyle3, speakersIconBgStyle, closeBtnContainer, searchPodcastInputContainer, searchPodcastInput, searchPodcastBtn, podcastModalContainer, speakersDetailsContainer, playIconStyle4, allContainerFilled, footerIconStyle, realFooterContainer, podcastTitle, wzDetailsProgram, paperTitle, podcastSpeakers, podcastDate, podcastContainer, playIconPodcastContainer, paperPodcast, allTogetherIconBigStyle, playIconStyle2, allTogetherIconBigContainer, waveForm, gridPlayerContainer, hourPlayer, speakersPlayer, titlePlayer, allPlayerContainer, playerContainer, playIconContainer, playIconStyle, titleTextContainer, speakersIconStyle, speakersImageContainer, paper, paperMessage, footerContainer, carouselStyle, footer, footerBtn, popoverStyle, wzSubtitleFading, welcomeMessageContainer, wzText } = Styles();
 
    



    const [anchorElHome, setAnchorElHome] = useState(null);

    const handlePopoverOpenHome = (event) => {
        setAnchorElHome(event.currentTarget);
    };

    const handlePopoverCloseHome= () => {
        setAnchorElHome(null);
    };

    const openHome = Boolean(anchorElHome);

    const [anchorElAndroidLink, setAnchorElAndroidLink] = useState(null);

    const handlePopoverOpenAndroidLink = (event) => {
        setAnchorElAndroidLink(event.currentTarget);
    };

    const handlePopoverCloseAndroidLink= () => {
        setAnchorElAndroidLink(null);
    };

    const openAndroidLink = Boolean(anchorElAndroidLink);

    const [anchorEliOSLink, setAnchorEliOSLink] = useState(null);

    const handlePopoverOpeniOSLink = (event) => {
        setAnchorEliOSLink(event.currentTarget);
    };

    const handlePopoverCloseiOSLink= () => {
        setAnchorEliOSLink(null);
    };

    const openiOSLink = Boolean(anchorEliOSLink);


    const [anchorElPodcasts, setAnchorElPodcasts] = useState(null);

    const handlePopoverOpenPodcasts = (event) => {
        setAnchorElPodcasts(event.currentTarget);
    };

    const handlePopoverClosePodcasts= () => {
        setAnchorElPodcasts(null);
    };

    const openPodcasts = Boolean(anchorElPodcasts);




    





    const [playing, togglePlayRadio] = useAudio('https://s4.yesstreaming.net:7095/stream');

    const [currentTime, setCurrentTime] = useState(0);

    const [duration, setDuration] = useState(0);

    const handleCurrentTimeChange = (newValue) => {
        setCurrentTime(newValue);
    };
    const handlePodcastsFilesChange = (newValue) => {
        setServerPodcastItems(newValue);
    };




    const [searchPodcast, setSearchPodcast] = React.useState('');

    const handleChangeSearch = (event) => {
        setSearchPodcast(event.target.value);
    };

    return (
        <Grid style={{ position: 'relative', background: 'linear-gradient(135deg, rgba(3,52,75,1) 0%, rgba(6,152,218,1) 100%)',}}>
            <Wave
                fill="url(#gradient2)"
                paused={false}
                options={getBigWaveOptions(playing)}
                className={waveFormRight}
            >
                <defs>
                    <linearGradient id="gradient2" gradientTransform="rotate(180)">
                        <stop offset="20%"  stopColor="rgba(255, 255, 255, 0.11)" />
                        <stop offset="80%" stopColor="rgba(6, 152, 218, 0.5)" />
                    </linearGradient>
                </defs>
            </Wave>
            <Header bg={'white'} playing={playing}/>
            <Container className={allContainerFilled}>
                <Grid item xs={12} className={allTogetherIconBigContainer} style={{position: 'relative'}}>
                    <Paper className={paperPodcast}>
                        <Paper className={paperTitle}>
                            <p className={wzSubtitleFading} style={{opacity: 1, marginTop: 0, marginBottom: 0,}}>PODCASTS</p>
                        </Paper>
                        <Grid className={wzCardContainerRounded}>     
                            <Grid container style={{alignItems: 'center'}}>
                                <TextField
                                    className={wzTextInputLogin}
                                    label="Tíulo o palabras clave..."
                                    variant="outlined"
                                    type="search"
                                    id="custom-css-outlined-input"
                                    fullWidth={true}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><Button onClick={() => {getDataPodcast(conection, setServerPodcastItems, setRefreshing, setUrls, searchPodcast, 1, setPagination, setCurrentPage)}}><SearchIcon /></Button></InputAdornment>,
                                        onKeyPress: event => {
                                            const { key } = event;
                                            //console.log(key);
                                            if (key === "Enter") {
                                                getDataPodcast(conection, setServerPodcastItems, setRefreshing, setUrls, searchPodcast, 1, setPagination, setCurrentPage)
                                            }
                                        }
                                    }}
                                    onChange={handleChangeSearch}
                                    value={searchPodcast}
                                />
                            </Grid>
                        </Grid>
                        <div className={wzVerticalSpace1}/>
                        {
                            !refreshing ?
                                <MultiPlayer
                                    urls={urls}
                                    podcasts={serverPodcastItems}
                                    currentTime={currentTime}
                                    setCurrentTime={handleCurrentTimeChange}
                                    setPodcastsFiles={handlePodcastsFilesChange}
                                />
                            :
                                <p className="align-middle text-center">Obteniendo podcasts...</p>
                        }
                        <Grid container direction="row" alignItems="center" justify="center">
                            <IconButton
                                onClick={() => {
                                    if(currentPage-1 < 1){
                                        Swal.fire({
                                            icon: 'info',
                                            title: 'Unión Sin Fronteras',
                                            html: 'Ya estás en la primera página',
                                            confirmButtonText:
                                                'Aceptar',
                                        })
                                    }else{
                                        setCurrentPage(currentPage-1)
                                        getDataPodcast(conection, setServerPodcastItems, setRefreshing, setUrls, searchPodcast, currentPage-1, setPagination, setCurrentPage)
                                    }
                                }}
                                style={{padding: '0.5vh'}}
                            >
                                <ArrowLeftIcon className={wzTextHuge} style={{color: 'white'}} />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setCurrentPage(1)
                                    getDataPodcast(conection, setServerPodcastItems, setRefreshing, setUrls, searchPodcast, 1, setPagination, setCurrentPage)
                                }}
                                className={wzTextNormal}
                            >
                                <p className={wzTextNormal} style={{textAlign: 'center', marginTop: 0, marginBottom: 0, lineHeight: '3.5vh', color: 'white'}}>{currentPage} / {pagination.total_pages}</p>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    if(currentPage+1 <= pagination.total_pages){
                                        setCurrentPage(currentPage+1)
                                        getDataPodcast(conection, setServerPodcastItems, setRefreshing, setUrls, searchPodcast, currentPage+1, setPagination, setCurrentPage)
                                    }else{
                                        Swal.fire({
                                            icon: 'info',
                                            title: 'Unión Sin Fronteras',
                                            html: 'Ya estás en la última página',
                                            confirmButtonText:
                                                'Aceptar',
                                        })
                                    }
                                }}
                                style={{padding: '0.5vh'}}
                            >
                                <ArrowRightIcon className={wzTextHuge} style={{color: 'white'}} />
                            </IconButton>
                        </Grid>
                    </Paper>
                </Grid>

                <div className={wzVerticalSpace1}/>
                <div className={wzVerticalSpace1}/>
                <div className={wzVerticalSpace1}/>



                <Grid container>
                    <Grid item xs={12} className={footerContainer}>
                        <BottomNavigation
                            className={footer}
                        >
                            <Button
                                className={footerBtn}
                                variant="contained"
                                onMouseEnter={handlePopoverOpeniOSLink}
                                onMouseLeave={handlePopoverCloseiOSLink}
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://bit.ly/usfappios', "_blank")
                                }}
                            >
                                <AppleIcon className={footerIconStyle} style={{color: 'rgba(3,52,75,1)'}}/>
                            </Button>
                            <Popover
                                id="mouse-over-popover"
                                className={popoverStyle}
                                classes={{
                                    paper: paper,
                                }}
                                open={openiOSLink}
                                anchorEl={anchorEliOSLink}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverCloseiOSLink}
                                disableRestoreFocus
                            >
                                <Typography>Aplicación para iOS</Typography>
                            </Popover>
                            <Button
                                className={footerBtn}
                                variant="contained"
                                onMouseEnter={handlePopoverOpenPodcasts}
                                onMouseLeave={handlePopoverClosePodcasts}
                                onClick={() => {
                                    
                                }}
                            >
                                <img src={podcastIcon} className={playIconStyle2}/>
                            </Button>
                            <Popover
                                id="mouse-over-popover"
                                className={popoverStyle}
                                classes={{
                                    paper: paper,
                                }}
                                open={openPodcasts}
                                anchorEl={anchorElPodcasts}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverClosePodcasts}
                                disableRestoreFocus
                            >
                                <Typography>Podcasts</Typography>
                            </Popover>
                            <Button
                                className={footerBtn}
                                variant="contained"
                                onMouseEnter={handlePopoverOpenHome}
                                onMouseLeave={handlePopoverCloseHome}
                                onClick={() => {
                                    props.history.push({
                                        pathname: "/",
                                        //search: "?sort=name",
                                        //hash: "#the-hash",
                                        state: {
                                            conection: conection
                                            //adminType: response.data
                                        }
                                    });
                                }}
                            >
                                <HomeIcon className={footerIconStyle} style={{color: 'rgba(255, 255, 255, 0.4)'}}/>
                            </Button>
                            <Popover
                                id="mouse-over-popover"
                                className={popoverStyle}
                                classes={{
                                    paper: paper,
                                }}
                                open={openHome}
                                anchorEl={anchorElHome}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverCloseHome}
                                disableRestoreFocus
                            >
                                <Typography>Inicio</Typography>
                            </Popover>
                            <Button
                                className={footerBtn}
                                variant="contained"
                                onMouseEnter={handlePopoverOpenAndroidLink}
                                onMouseLeave={handlePopoverCloseAndroidLink}
                                onClick={() => {
                                    window.open('https://bit.ly/usfappandroid', "_blank")
                                }}
                            >
                                <AndroidIcon className={footerIconStyle} style={{color: 'rgba(3,52,75,1)'}}/>
                            </Button>
                            <Popover
                                id="mouse-over-popover"
                                className={popoverStyle}
                                classes={{
                                    paper: paper,
                                }}
                                open={openAndroidLink}
                                anchorEl={anchorElAndroidLink}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverCloseAndroidLink}
                                disableRestoreFocus
                            >
                                <Typography>Aplicación para Android</Typography>
                            </Popover>
                        </BottomNavigation>
                    </Grid>
                </Grid>
            </Container>
            {
                refreshing ?
                    <Grid className={wzWaitingContianer}>
                        <p className={wzTextHuge} style={{color: 'white', fontWeight: 'bold', textAlign: 'center'}}>Cargando...</p>
                    </Grid>
                :
                    null
            }
        </Grid>
    );
}

export default withRouter(Podcasts);