import React from "react";

import { makeStyles } from '@material-ui/core/styles';

// returns the style
function Styles (){
    const useStyles = makeStyles((theme) => ({
        allContainerFilled: {
            //#0698da azul bajito
            flexGrow: 1,
            //background: 'linear-gradient(135deg, rgba(3,52,75,1) 0%, rgba(6,152,218,1) 100%)',
            paddingBottom: 0,
            [theme.breakpoints.up('xs')]: {
                paddingTop: '0.7em',
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: '1.1em',
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: '1.4em',
            },
        },
        allContainer2: {
            //#0698da azul bajito
            flexGrow: 1,
            //background: 'linear-gradient(135deg, rgba(10, 29, 48, 1) 0%, rgba(3,52,75,1) 100%)',
            paddingBottom: 0
        },
        closeBtnContainer:{
            position: 'absolute',
            
            [theme.breakpoints.up('xs')]: {
                top: '-0.5em',
                right: '-0.5em'
            },
            [theme.breakpoints.up('sm')]: {
                top: '-1em',
                right: '-1em'
            },
            [theme.breakpoints.up('md')]: {
                top: '-2em',
                right: '-2em'
            },
        },
        closeBtn:{
            background: 'rgba(6,152,218,1)',
            [theme.breakpoints.up('xs')]: {
                padding: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                padding: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                padding: '1em',
            },
            borderRadius: '20em',
            boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2)',

            '&:hover': {
                background: 'rgba(3,52,75,1)',
                boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.5)',
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.primary,
        },
        paperPodcast: {
            paddingTop: 0,
            background: 'linear-gradient(90deg, rgba(6,152,218,1) 0%, rgba(165,236,59,1) 100%)',
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.primary,
        },
        podcastModalContainer:{
            [theme.breakpoints.up('xs')]: {
                padding: '1em',
            },
            [theme.breakpoints.up('sm')]: {
                padding: '2em',
            },
            [theme.breakpoints.up('md')]: {
                padding: '4em',
            },
            borderWidth: 0,
            borderStyle: 'none'
        },
        searchPodcastInputContainer:{
            borderRadius: '20em',
            background: 'rgba(255, 255, 255, 0.7)',
            alignItems: 'center',
            [theme.breakpoints.up('xs')]: {
                paddingLeft: '1em',
                paddingRight: '1em',
                paddingTop: '0.15em',
                paddingBottom: '0.15em',
                marginBottom: '0.5em',
            },
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '1.5em',
                paddingRight: '1.5em',
                paddingTop: '0.3em',
                paddingBottom: '0.3em',
                marginBottom: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                padding: '2em',
                padding: '2em',
                paddingTop: '0.5em',
                paddingBottom: '0.5em',
                marginBottom: '1em',
            },
        },
        searchPodcastInput:{
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.7em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.2em',
            },
        },
        searchPodcastBtn:{
            background: 'rgba(6,152,218,1)',
            [theme.breakpoints.up('xs')]: {
                padding: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                padding: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                padding: '1em',
            },
            borderRadius: '10em',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,

            '&:hover': {
                background: 'rgba(3,52,75,1)',
                //boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.5)',
            },
        },
        paperMessage: {
            textAlign: 'center',
            color: theme.palette.text.primary,
            background: 'rgba(255, 255, 255, 0)',
            boxShadow: '0px 0px 10px 2px rgba(36,192,215,0)',
            background: 'linear-gradient(90deg, rgba(3,52,75,0) 0%, rgba(6,152,218,1) 50%, rgba(3,52,75,0) 100%)',
            //background: 'linear-gradient(90deg, rgba(114,184,45,0) 0%, rgba(114,184,45,1) 50%, rgba(114,184,45,0) 100%)',
            padding: theme.spacing(2),
            borderRadius: 0,
            [theme.breakpoints.up('xs')]: {
                paddingTop: '0.08em',
                paddingBottom: '0.08em',
                marginTop: '-0.2em',
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: '0.1em',
                paddingBottom: '0.1em',
                marginTop: '-0.25em',
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: '0.2em',
                paddingBottom: '0.2em',
                marginTop: '-0.5em',
            },
        },
        paperTitle: {
            textAlign: 'center',
            color: theme.palette.text.primary,
            background: 'rgba(255, 255, 255, 0)',
            //boxShadow: '0px 0px 10px 2px rgba(36,192,215,0.4)',
            background: 'linear-gradient(135deg, rgba(3,52,75,1) 0%, rgba(6,152,218,1) 100%)',
            padding: theme.spacing(2),
            [theme.breakpoints.up('xs')]: {
                paddingTop: '0.16em',
                paddingBottom: '0.16em',
                marginBottom: '0.4em',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: '0.4em',
                borderBottomRightRadius: '0.4em'
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: '0.3em',
                paddingBottom: '0.3em',
                marginBottom: '0.8em',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: '0.8em',
                borderBottomRightRadius: '0.8em'
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: '0.4em',
                paddingBottom: '0.4em',
                marginBottom: '1em',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: '1em',
                borderBottomRightRadius: '1em'
            },
        },
        paperCounter: {
            textAlign: 'center',
            color: theme.palette.text.primary,
            background: 'rgba(140,200,50,1)',
            //boxShadow: '0px 0px 10px 2px rgba(36,192,215,1)',
            //background: 'linear-gradient(135deg, rgba(3,52,75,1) 0%, rgba(3,52,75,1) 100%)',
            padding: theme.spacing(2),
            borderTopRightRadius: 0,
            
            position: 'absolute',
            top: 0,
            right: 0,
            [theme.breakpoints.up('xs')]: {
                paddingTop: '0.08em',
                paddingBottom: '0.08em',
                marginBottom: '0.4em',
                borderBottomRightRadius: '0.2em',
                borderTopLeftRadius: '0.2em',
                borderBottomLeftRadius: '2em',
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: '0.2em',
                paddingBottom: '0.2em',
                marginBottom: '0.8em',
                borderBottomRightRadius: '0.8em',
                borderTopLeftRadius: '0.8em',
                borderBottomLeftRadius: '5em',
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: '0.4em',
                paddingBottom: '0.4em',
                marginBottom: '1em',
                borderBottomRightRadius: '0.8em',
                borderTopLeftRadius: '0.8em',
                borderBottomLeftRadius: '6em',
            },
        },
        paperHourDay: {
            //color: theme.palette.text.primary,
            background: 'rgba(255, 255, 255, 0)',
            boxShadow: '5px 0px 5px 2px rgba(255,255,255,0)',
            //background: 'linear-gradient(135deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.1) 100%)',
            padding: theme.spacing(2),
            borderTopRightRadius: '2em',
            borderBottomRightRadius: '2em',
            marginRight: '1em',
            [theme.breakpoints.up('xs')]: {
                paddingTop: '0.08em',
                paddingBottom: '0.08em',
                marginLeft: '-0.3em',
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: '0.1em',
                paddingBottom: '0.1em',
                marginLeft: '-0.6em',
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: '0.2em',
                paddingBottom: '0.2em',
                marginLeft: '-0.8em',
            },
        },
        paperDescription: {
            //color: theme.palette.text.primary,
            //background: 'rgba(255, 255, 255, 0)',
            boxShadow: '0px 5px 5px 2px rgba(255,255,255,0.1)',
            background: 'linear-gradient(135deg, rgba(3,52,75,1) 0%, rgba(6,152,218,0.5)  100%)',
            padding: theme.spacing(2),
            borderTopLeftRadius: '2em',
            borderBottomLeftRadius: '2em',
            [theme.breakpoints.up('xs')]: {
                paddingTop: '0.08em',
                paddingBottom: '0.08em',
                marginLeft: '-0.3em',
                marginTop: '0.8em',
                marginBottom: '0.8em'
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: '0.1em',
                paddingBottom: '0.1em',
                marginLeft: '-0.6em',
                marginTop: '1em',
                marginBottom: '1em'
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: '0.2em',
                paddingBottom: '0.2em',
                marginLeft: '-0.8em',
                marginTop: '2em',
                marginBottom: '2em'
            },
        },
        titleHeaderContainer: {
            //boxShadow: '0px 0px 10px 2px rgba(36,192,215,0.4)',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.up('xs')]: {
                //background: 'linear-gradient(180deg, rgba(4,78,111,0) 0%, rgba(6,152,218,0.6) 100%)',
                //background: 'linear-gradient(180deg, rgba(114,184,45,0.1) 0%, rgba(6,152,218,0.5) 100%)',
                marginTop: theme.spacing(2),
                borderTopLeftRadius: '1em',
                borderTopRightRadius: '1em',
            },
            [theme.breakpoints.up('sm')]: {
                //background: 'linear-gradient(90deg, rgba(4,78,111,0) 20%, rgba(6,152,218,1) 100%)',
                //background: 'linear-gradient(180deg, rgba(114,184,45,0.1) 0%, rgba(6,152,218,0.5) 100%)',
                marginTop: theme.spacing(0),
                height: '100%',
                borderTopLeftRadius: '10em',
                borderTopRightRadius: '10em',
            },
        },
        logoHeaderContainer: {
            //background: 'red',
            display: 'flex',
            'justify-content': 'center',
            [theme.breakpoints.up('xs')]: {
                paddingTop: '1em',
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: '1em',
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: '1em',
            },
        },
        micUpContainer:{
            position: 'absolute',
            top: 0,
        },
        nameStyle: {
            //background: 'gray',
            
            [theme.breakpoints.up('xs')]: {
                height: '3em',
                width: '12em',
                marginTop: '-0.1em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '4em',
                width: '25em',
                marginTop: '-0.5em',
            },
            [theme.breakpoints.up('md')]: {
                height: '5em',
                width: '30em',
                marginTop: '-1em',
            },
            objectFit: 'contain',
        },
        nameBg:{
            borderRadius: '2em',
            position: 'absolute',
            bottom: '0.1em',
            right: '-0.2em',
            left: '-0.2em',
            height: '0.8em',
            [theme.breakpoints.up('xs')]: {
                background: 'rgba(255, 255, 255, 0)',
            },
            [theme.breakpoints.up('sm')]: {
                background: 'rgba(255, 255, 255, 0)',
            },
            [theme.breakpoints.up('md')]: {
                background: 'rgba(255, 255, 255, 0.8)',
            },
        },
        nameBg2:{
            borderRadius: '2em',
            position: 'absolute',
            [theme.breakpoints.up('xs')]: {
                top: 'rgba(255, 255, 255, 0)',
            },
            [theme.breakpoints.up('sm')]: {
                top: 'rgba(255, 255, 255, 0)',
            },
            [theme.breakpoints.up('md')]: {
                top: '2em',
                left: '80em',
            },
            bottom: '0.1em',
            right: '-0.2em',
            height: '1em',
            background: 'rgba(255, 0, 0, 1)',
        },
        logoHeader: {
            //background: 'gray',
            
            [theme.breakpoints.up('xs')]: {
                height: '1.5em',
                width: '1.5em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '3em',
                width: '3em',
            },
            [theme.breakpoints.up('md')]: {
                height: '5em',
                width: '5em',
            },
            objectFit: 'contain',
        },
        headerContainer: {
            background: 'white',
            //boxShadow: '0px 0px 10px 2px rgba(36,192,215,0.4)',           
            paddingBottom: 0,
            alignItems: 'center',
            /*position: '-webkit-sticky',
            position: 'sticky',
            top: 0,*/
            [theme.breakpoints.up('xs')]: {
                paddingTop: theme.spacing(2),
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(1),
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(1),
            },
        },
        wzVerticalSpace1:{
            [theme.breakpoints.up('xs')]: {
                height: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                height: '1em',
            },
        },
        wzButtonImageContainer:{
            color: 'dark',
            background: 'rgba(0,0,0,0)',
            boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
            '&:hover': {
                background: 'rgba(0,0,0,0)',
                boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
            },
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
        wzCardContainerRounded:{
            background: 'white',
            [theme.breakpoints.up('xs')]: {
                //boxShadow: '0.5px 2px 4px 0.5px rgba(0, 0, 0, 0.2)',
                padding: '1.1em',
                borderRadius: '1.2em',
                borderColor: 'rgba(0, 255, 255, 0.2)',
                borderWidth: '0.2em',
                borderStyle: 'solid',
            },
            [theme.breakpoints.up('sm')]: {
                //boxShadow: '1px 3px 6px 1px rgba(0, 0, 0, 0.2)',
                padding: '1.3em',
                borderRadius: '1.6em',
                borderColor: 'rgba(0, 255, 255, 0.2)',
                borderWidth: '0.3em',
                borderStyle: 'solid',
            },
            [theme.breakpoints.up('md')]: {
                //boxShadow: '2px 4px 8px 2px rgba(0, 0, 0, 0.2)',
                padding: '1.5em',
                borderRadius: '2em',
                borderColor: 'rgba(0, 255, 255, 0.2)',
                borderWidth: '0.4em',
                borderStyle: 'solid',
            },
        },
        wzTextInputLogin:{
            '& label.Mui-focused': {
                color: '#24C0D7',
            },
            '& .MuiInputBase-root': {
                color: "#3c58a1",
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    //background: 'rgba(255, 255, 255, 0.7)',
                    borderColor: '#24C0D7', //Azul bajito
                    [theme.breakpoints.up('xs')]: {
                        borderRadius: '0.6em',
                        borderWidth: '0.13em',
                    },
                    [theme.breakpoints.up('sm')]: {
                        borderRadius: '0.8em',
                        borderWidth: '0.15em',
                    },
                    [theme.breakpoints.up('md')]: {
                        borderRadius: '1em',
                        borderWidth: '0.17em',
                    },
                },
                '&:hover fieldset': {
                    //background: 'rgba(255, 255, 255, 0.7)',
                    borderColor: '#24C0D7', //Azul bajito
                    [theme.breakpoints.up('xs')]: {
                        borderRadius: '0.6em',
                        borderWidth: '0.13em',
                    },
                    [theme.breakpoints.up('sm')]: {
                        borderRadius: '0.8em',
                        borderWidth: '0.15em',
                    },
                    [theme.breakpoints.up('md')]: {
                        borderRadius: '1em',
                        borderWidth: '0.17em',
                    },
                },
                '&.Mui-focused fieldset': {
                    //background: 'rgba(255, 255, 255, 0.7)',
                    borderColor: '#24C0D7', //Azul bajito
                    [theme.breakpoints.up('xs')]: {
                        borderRadius: '0.6em',
                        borderWidth: '0.13em',
                    },
                    [theme.breakpoints.up('sm')]: {
                        borderRadius: '0.8em',
                        borderWidth: '0.15em',
                    },
                    [theme.breakpoints.up('md')]: {
                        borderRadius: '1em',
                        borderWidth: '0.17em',
                    },
                },
            },
        },
        wzTextInputLoginFilled: {
            '& label.Mui-focused': {
                color: '#24C0D7',
            },
            '& .MuiInputBase-root': {
                color: "#3c58a1",
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#24C0D7', //Azul bajito
                    [theme.breakpoints.up('xs')]: {
                        borderRadius: '0.6em',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderWidth: '0.13em',
                    },
                    [theme.breakpoints.up('sm')]: {
                        borderRadius: '0.8em',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderWidth: '0.15em',
                    },
                    [theme.breakpoints.up('md')]: {
                        borderRadius: '1em',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderWidth: '0.17em',
                    },
                },
                '&:hover fieldset': {
                    borderColor: '#24C0D7', //Azul bajito
                    [theme.breakpoints.up('xs')]: {
                        borderRadius: '0.6em',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderWidth: '0.13em',
                    },
                    [theme.breakpoints.up('sm')]: {
                        borderRadius: '0.8em',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderWidth: '0.15em',
                    },
                    [theme.breakpoints.up('md')]: {
                        borderRadius: '1em',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderWidth: '0.17em',
                    },
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#24C0D7', //Azul bajito
                    [theme.breakpoints.up('xs')]: {
                        borderRadius: '0.6em',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderWidth: '0.13em',
                    },
                    [theme.breakpoints.up('sm')]: {
                        borderRadius: '0.8em',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderWidth: '0.15em',
                    },
                    [theme.breakpoints.up('md')]: {
                        borderRadius: '1em',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderWidth: '0.17em',
                    },
                },
            },
        },
        wzButtonLogin:{
            color: 'white',
            background: '#40508F',
            boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
            '&:hover': {
                background: '#273B8C',
                boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
            },
            [theme.breakpoints.up('xs')]: {
                borderRadius: '2em',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                paddingTop: '1.15em',
                paddingBottom: '1.15em'
            },
            [theme.breakpoints.up('sm')]: {
                borderRadius: '3em',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                paddingTop: '1.15em',
                paddingBottom: '1.15em'
            },
            [theme.breakpoints.up('md')]: {
                borderRadius: '5em',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                paddingTop: '1.15em',
                paddingBottom: '1.15em'
            },
        },
        wzWaitingContianer:{
            background: 'rgba(0, 0, 0, 0.65)',
            display: 'flex',
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
        },
        wzTextHuge:{
            color: '#2C2C2C',
            //marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: '1.4em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.6em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.8em',
            },
        },
        wzText: {
            color: 'rgba(3,52,75,1)',
            //textShadow: '2px 2px 5px rgba(36,192,215,0.8)',
            //textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            margin: 0,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            textAlign: 'left',
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.1em',
            },
        },
        programTitle: {
            color: 'white',
            //textShadow: '2px 2px 5px rgba(36,192,215,0.8)',
            textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            fontWeight: 'bold',
            margin: 0,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: '1em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.4em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.8em',
            },
        },
        wzSubtitleFading: {
            color: 'white',
            //textShadow: '2px 2px 5px rgba(36,192,215,0.8)',
            textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            fontWeight: 'bold',
            margin: 0,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.7em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.3em',
            },
        },
        wzDetailsProgram: {
            color: 'white',
            textShadow: '2px 2px 5px rgba(3,52,75,0.8)',
            //textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            fontWeight: 'bold',
            margin: 0,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.05em',
            },
        },
        wzDetailsHourProgram: {
            color: 'white',
            //textShadow: '2px 2px 5px rgba(3,52,75,0.8)',
            //textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            fontWeight: 'bold',
            margin: 0,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.05em',
            },
        },
        wzDetailsDayProgram: {
            color: 'white',
            textShadow: '2px 2px 5px rgba(3,52,75,0.8)',
            //textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            fontWeight: 'bold',
            margin: 0,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.7em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.1em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.4em',
            },
        },
        wzDescriptionProgram: {
            color: 'white',
            //textShadow: '2px 2px 5px rgba(36,192,215,0.8)',
            //textShadow: '2px 2px 5px rgba(255,255,255,0.8)',
            //fontWeight: 'bold',
            textAlign: 'right',
            margin: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.9em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.2em',
            },
        },
        wzSubtitle: {
            color: 'rgba(3,52,75,0.8)',
            //textShadow: '2px 2px 5px rgba(36,192,215,0.8)',
            //textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            fontWeight: 'normal',
            margin: 0,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            textAlign: 'center',
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.9em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.5em',
            },
        },
        wzTitle: {
            color: '#0698da',
            textShadow: '1px 1px 3px rgba(36,192,215,0.8)',
            //textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            fontWeight: 'bold',
            textAlign: 'center',
            margin: 0,
            marginTop: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: '1.1em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.8em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '2.5em',
            },
        },
        realFooterContainer:{
            background: 'linear-gradient(90deg, rgba(6,152,218,0) 0%, rgba(3,52,75,0.5) 20%, rgba(3,52,75,1) 50%, rgba(3,52,75,0.5) 80%, rgba(6,152,218,0) 100%)',
            //alignItems: 'center',
            [theme.breakpoints.up('xs')]: {
                paddingBottom: '4em',
                paddingLeft: '2em',
                paddingRight: '2em',
            },
            [theme.breakpoints.up('sm')]: {
                paddingBottom: '4em',
                paddingLeft: '7em',
                paddingRight: '7em',
            },
            [theme.breakpoints.up('md')]: {
                paddingBottom: '5em',
                paddingLeft: '9em',
                paddingRight: '9em',
            },
        },
        footer: {
            [theme.breakpoints.up('xs')]: {
                width: '100%'
            },
            [theme.breakpoints.up('sm')]: {
                width: '80%'
            },
            [theme.breakpoints.up('md')]: {
                width: '60%'
            },
            margin: 'auto',
            borderTopRightRadius: '3em',
            borderTopLeftRadius: '3em',
            boxShadow: '0px 0px 10px 2px rgba(153, 255, 0, 0.3)',
            background: '#72B82D',
        },
        footerContainer: {
            position: 'fixed',
            left: 0,
            bottom: 0,
            right: 0,
            textAlign: 'center',
            zIndex: 20,
        },
        footerBtn:{
            color: 'dark',
            background: 'rgba(0,0,0,0)',
            boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
            '&:hover': {
                background: 'rgba(0,0,0,0.1)',
                boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.5)',
            },
            'border-radius': 0,
            [theme.breakpoints.up('xs')]: {
                'padding-left': '1.2em',
                'padding-right': '1.2em',
            },
            [theme.breakpoints.up('sm')]: {
                'padding-left': '2.5em',
                'padding-right': '2.5em',
            },
            [theme.breakpoints.up('md')]: {
                'padding-left': '3em',
                'padding-right': '3em',
            },
        },
        popoverStyle:{
            pointerEvents: 'none',
        },
        welcomeMessageContainer:{
            position: '-webkit-sticky',
            position: 'sticky',
            top: 0,
            'zIndex': 20,
            marginBottom: theme.spacing(1),
        },
        playerContainer:{
            'justify-content': 'center',
            alignItems: 'center',
            textAlign: 'center',
            zIndex: 10,
        },
        podcastContainer:{
            background: 'rgba(3,52,75,0.1)',
            alignItems: 'center',
            padding: 0,
            overflow: 'hidden',
            [theme.breakpoints.up('xs')]: {
                paddingTop: '0em',
                paddingBottom: '0em',
                borderRadius: '0.5em',
                marginBottom: '0.1em'
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: '0.1em',
                paddingBottom: '0.1em',
                borderRadius: '0.7em',
                marginBottom: '0.3em'
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: '0.2em',
                paddingBottom: '0.2em',
                borderRadius: '1em',
                marginBottom: '0.5em'
            },
            '&:hover': {
                background: 'rgba(3,52,75,0.5)',
            },
        },
        podcastTitle:{
            color: 'white',
            //textShadow: '2px 2px 5px rgba(36,192,215,0.8)',
            textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            fontWeight: '500',
            textAlign: 'left',
            margin: 0,
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.7em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.2em',
            },
        },
        podcastSpeakers:{
            color: 'rgba(255, 255, 255, 0.7)',
            //textShadow: '2px 2px 5px rgba(36,192,215,0.8)',
            //textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            fontStyle: 'italic',
            textAlign: 'left',
            margin: 0,
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.7em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1em',
            },
        },
        podcastDate:{
            color: 'rgba(3,52,75,1)',
            //textShadow: '2px 2px 5px rgba(36,192,215,0.8)',
            //textShadow: '2px 2px 5px rgba(140,200,50,0.8)',
            'font-family': 'Arial, sans-serif, monospace',
            fontStyle: 'italic',
            textAlign: 'right',
            margin: 0,
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.7em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1em',
            },
            '&:hover': {
                color: 'white',
            },
        },
        facebookContainer:{
            background: 'rgba(3,52,75,0.1)',
            alignItems: 'center',
            [theme.breakpoints.up('xs')]: {
                padding: '0.3em',
                paddingTop: '0em',
                paddingBottom: '0em',
                borderRadius: '0.5em',
                marginBottom: '0.1em'
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: '0.1em',
                paddingBottom: '0.1em',
                padding: '0.7em',
                borderRadius: '0.7em',
                marginBottom: '0.3em'
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: '0.2em',
                paddingBottom: '0.2em',
                padding: '1em',
                borderRadius: '1em',
                marginBottom: '0.5em'
            },
            '&:hover': {
                background: 'rgba(3,52,75,0.5)',
            },
        },
        facebookMini:{
            [theme.breakpoints.up('xs')]: {
                height: '0.7em',
                width: '0.7em',
                marginRight: '0.1em'
            },
            [theme.breakpoints.up('sm')]: {
                height: '1em',
                width: '1em',
                marginRight: '0.2em'
            },
            [theme.breakpoints.up('md')]: {
                height: '1.5em',
                width: '1.5em',
                marginRight: '0.5em'
            },
        },
        playIconPodcastContainer:{
            boxShadow: '3px 0px 5px 2px rgba(3,52,75,0.2)',
            background: 'linear-gradient(135deg, rgba(6,152,218,0.3) 0%, rgba(3,52,75,0.3) 100%)',
            'justify-content': 'center',
            alignItems: 'center',
            borderTopRightRadius: '4em',
            borderBottomRightRadius: '4em',


            [theme.breakpoints.up('xs')]: {
                height: '1.5em',
                width: '1.5em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '2.5em',
                width: '2.5em',
            },
            [theme.breakpoints.up('md')]: {
                height: '3.5em',
                width: '3.5em',
            },

            '&:hover': {
                background: 'linear-gradient(135deg, rgba(6,152,218,1) 0%, rgba(3,52,75,1) 100%)',
                boxShadow: '3px 0px 5px 2px rgba(3,52,75,0.8)',
            },
        },
        playIconContainer:{
            //boxShadow: '-3px 0px 5px 2px rgba(255,255,255,0.2)',
            //background: 'linear-gradient(135deg, rgba(114,184,45,1) 0%, rgba(140,200,50,1) 100%)',
            'justify-content': 'center',
            alignItems: 'center',
            //borderTopLeftRadius: '4em',
            //borderBottomLeftRadius: '4em',
            borderRadius: '10em',

            [theme.breakpoints.up('xs')]: {
                height: '3em',
                width: '3em',
                marginTop: '-0.5em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '4em',
                width: '4em',
                marginTop: '-1em',
            },
            [theme.breakpoints.up('md')]: {
                height: '6em',
                width: '6em',
                marginTop: '-1.5em',
            },

            '&:hover': {
                background: 'linear-gradient(135deg, rgba(140,200,50,1) 0%, rgba(165,236,59,1) 100%)',
                boxShadow: '0px 0px 5px 2px rgba(165,236,59,0.5)',
            },
            /*'&:focus': {
                background: 'linear-gradient(135deg, rgba(114,184,45,1) 0%, rgba(140,200,50,1) 100%)',
                outline: 'none',
                boxShadow: '-3px 0px 5px 2px rgba(255,255,255,0.2)',
            }*/
        },
        playIconStyle: {
            [theme.breakpoints.up('xs')]: {
                height: '2.5em',
                width: '2.5em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '3em',
                width: '3em',
            },
            [theme.breakpoints.up('md')]: {
                height: '4em',
                width: '4em',
            },
            objectFit: 'contain',
        },
        playIconStyle2: {
            [theme.breakpoints.up('xs')]: {
                height: '1em',
                width: '1em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '1.5em',
                width: '1.5em',
            },
            [theme.breakpoints.up('md')]: {
                height: '2em',
                width: '2em',
            },
            objectFit: 'contain',
        },
        playIconStyle4: {
            [theme.breakpoints.up('xs')]: {
                height: '1.2em',
                width: '1.2em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '1.8em',
                width: '1.8em',
            },
            [theme.breakpoints.up('md')]: {
                height: '3em',
                width: '3em',
            },
            objectFit: 'contain',
        },
        playIconStyle3: {
            [theme.breakpoints.up('xs')]: {
                height: '0.7em',
                width: '0.7em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '1em',
                width: '1em',
            },
            [theme.breakpoints.up('md')]: {
                height: '1.3em',
                width: '1.3em',
            },
            objectFit: 'contain',
        },
        playIconStyle5: {
            [theme.breakpoints.up('xs')]: {
                height: '0.6em',
                width: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '0.8em',
                width: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                height: '1.1em',
                width: '1.1em',
            },
            objectFit: 'contain',
        },
        footerIconStyle: {
            [theme.breakpoints.up('xs')]: {
                height: '1em',
                width: '1em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '1.2em',
                width: '1.2em',
            },
            [theme.breakpoints.up('md')]: {
                height: '1.4em',
                width: '1.4em',
            },
            objectFit: 'contain',
        },
        carouselStyle:{
            //marginRight: theme.spacing(2)*-1,
            //marginLeft: theme.spacing(2)*-1,
            /*[theme.breakpoints.up('xs')]: {
                marginRight: '5em',
            },
            [theme.breakpoints.up('sm')]: {
                marginRight: '5em',
            },
            [theme.breakpoints.up('md')]: {
                marginRight: '16.2em',
            },*/
        },
        gridPlayerContainer:{
            //display: 'flex',
            overflow: 'hidden',
            position: 'relative',
            //background: 'red',
            background: 'linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 100%)',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.up('xs')]: {
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: '0.5em',
                borderBottomRightRadius: '0.5em',
            },
            [theme.breakpoints.up('sm')]: {
                borderRadius: 0,
                borderTopRightRadius: '0.8em',
                borderBottomRightRadius: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                borderRadius: 0,
                borderTopRightRadius: '1em',
                borderBottomRightRadius: '1em',
            },
            boxShadow: '-2px 0px 5px 1px rgba(36,192,215,0.4)',
        },
        waveForm:{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 0,
            [theme.breakpoints.up('xs')]: {
                top: '5.8em',
            },
            [theme.breakpoints.up('sm')]: {
                top: '8.2em',
            },
            [theme.breakpoints.up('md')]: {
                top: '12.5em',
            },
        },
        waveFormRight:{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 0,
            [theme.breakpoints.up('xs')]: {
                height: '5em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '5em',
            },
            [theme.breakpoints.up('md')]: {
                height: '5em',
            },
        },
        waveFormTop:{
            overflow: 'hidden',
            position: 'absolute',
            left: '15%',
            right: '15%',
            bottom: 0,
            zIndex: 0,
            width: 'auto',
            [theme.breakpoints.up('xs')]: {
                height: '2em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '3em',
            },
            [theme.breakpoints.up('md')]: {
                height: '4.5em',
            },
        },
        speakersDetailsContainer:{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            /*[theme.breakpoints.up('xs')]: {
                padding: '0.5em',
            },
            [theme.breakpoints.up('sm')]: {
                padding: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                padding: '1em',
            },*/
        },
        speakersIconStyle: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            [theme.breakpoints.up('xs')]: {
                height: '4em',
                left: '35%',
            },
            [theme.breakpoints.up('sm')]: {
                height: '5em',
                left: '14%',
            },
            [theme.breakpoints.up('md')]: {
                height: '8em',
                left: 0,
            },
            objectFit: 'contain',
            zIndex: 200
        },
        speakersIconStyleContainer:{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                //transform: 'translate(-55%, 0)',
                zIndex: 2,
            [theme.breakpoints.up('xs')]: {
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                width: '100%',
            },
        },
        speakersIconBgStyle:{
            filter: 'blur(0.2em)',
            '-webkit-filter': 'blur(0.2em)',
            
            /* Full height */
            height: '100%',
            width: '100%',
            
            /* Center and scale the image nicely */
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.5,
            zIndex: 0,
        },
        speakersIconContainer:{
            [theme.breakpoints.up('xs')]: {
                height: '4em',
                //marginRight: '7em',
                //marginLeft: '7em',
                marginBottom: '-0.5em',
                borderTopRightRadius: 0
            },
            [theme.breakpoints.up('sm')]: {
                height: '5em',
                //marginRight: '2em',
                //marginLeft: '2em',
                marginBottom: '-0.8em',
                borderTopRightRadius: '0.6em'
            },
            [theme.breakpoints.up('md')]: {
                height: '8em',
                //marginRight: 0,
                //marginLeft: 0,
                marginBottom: '-1em',
                borderTopRightRadius: '0.8em'
            },
            position: 'relative',
            overflow: 'hidden'
        },
        titleTextContainer:{
            position: 'relative',
            textAlign: 'center',
            zIndex: 99
        },
        titlePlayer:{
            color: 'rgba(3,52,75,1)',
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.6em',
                marginLeft: '0.5em',
                marginRight: '0.5em',
                marginBottom: '-0.25em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.8em',
                marginLeft: '0.8em',
                marginRight: '0.8em',
                marginBottom: '-0.4em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.1em',
                marginLeft: '1em',
                marginRight: '1em',
                marginBottom: '-0.5em',
            },
            fontWeight: 'bold',
            wordWrap: 'breakWord',
            
        },
        speakersPlayer:{
            color: 'rgba(3,52,75,0.7)',
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.5em',
                marginLeft: '0.5em',
                marginRight: '0.5em',
                marginBottom: '-0.25em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.7em',
                marginLeft: '0.8em',
                marginRight: '0.8em',
                marginBottom: '-0.4em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1em',
                marginLeft: '1em',
                marginRight: '1em',
                marginBottom: '-0.5em',
            },
            'font-style': 'italic',
            wordWrap: 'breakWord',
            marginBottom: 0,
        },
        hourPlayer:{
            color: 'rgba(3,52,75,1)',
            //color: 'white',
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.5em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.7em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1em',
            },
            position: 'relative',
            zIndex: 2,
            fontWeight: '500',
            fontStyle: 'italic',
            wordWrap: 'breakWord',
            textShadow: '1px 1px 2px rgba(40, 40, 40, 0.2)',
            //textShadow: '0px 0px 10px 5px rgba(0,0,0,0.2)',
        },
        
        speakersIconBigContainer:{
            [theme.breakpoints.up('xs')]: {
                paddingRight: '0.3em',
            },
            [theme.breakpoints.up('sm')]: {
                paddingRight: '0.6em',
            },
            [theme.breakpoints.up('md')]: {
                paddingRight: '0.8em',
            },
        },
        speakersIconBigStyle: {
            [theme.breakpoints.up('xs')]: {
                width: '100%',
                marginTop: '0.2em',
                borderRadius: '0.5em',
            },
            [theme.breakpoints.up('sm')]: {
                width: '100%',
                marginTop: '0.3em',
                borderRadius: '0.8em',
            },
            [theme.breakpoints.up('md')]: {
                width: '100%',
                marginTop: '0.5em',
                borderRadius: '1em',
            },
            overflow: 'hidden',
            objectFit: 'contain',
            boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.2)',
        },
        allTogetherIconBigContainer:{
            position: 'relative',
            [theme.breakpoints.up('xs')]: {
                marginBottom: '0.5em'
            },
            [theme.breakpoints.up('sm')]: {
                marginBottom: '1.5em'
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: '2em'
            },
        },
        allTogetherIconBigStyle: {
            [theme.breakpoints.up('xs')]: {
                width: '100%',
                borderTopLeftRadius: '0.5em',
                borderTopRightRadius: '0.5em',
                borderBottomLeftRadius: '4em',
                borderBottomRightRadius: '4em',
            },
            [theme.breakpoints.up('sm')]: {
                width: '100%',
                borderTopLeftRadius: '0.8em',
                borderTopRightRadius: '0.8em',
                borderBottomLeftRadius: '6em',
                borderBottomRightRadius: '6em',
            },
            [theme.breakpoints.up('md')]: {
                width: '100%',
                borderTopLeftRadius: '1em',
                borderTopRightRadius: '1em',
                borderBottomLeftRadius: '8em',
                borderBottomRightRadius: '8em',
            },
            overflow: 'hidden',
            objectFit: 'contain',
            boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.2)',
        },
        allPlayerContainer:{
            [theme.breakpoints.up('xs')]: {
                borderTopRightRadius: '0.5em',
                borderTopLeftRadius: '0.5em',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
            [theme.breakpoints.up('sm')]: {
                borderRadius: '0.8em',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            },
            [theme.breakpoints.up('md')]: {
                borderRadius: '1em',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            },
            position: 'relative',
            overflow: 'hidden',
            boxShadow: '0px 0px 5px 1px rgba(36,192,215,0.4)',
            background: 'linear-gradient(135deg, rgba(6,152,218,1) 0%, rgba(255,255,255,1) 100%)',
        },
        contactItemContainer: {
            alignItems: 'center',
            [theme.breakpoints.up('xs')]: {
                padding: '0.8em',
                paddingBottom: 0,
                paddingTop: 0,
                marginTop: '0.5em',
                marginBottom: '0.5em',
                borderWidth: '0.12em 0.2em 0.12em 0.08em',
                borderRadius: '1.5em'
            },
            [theme.breakpoints.up('sm')]: {
                padding: '1em',
                paddingBottom: 0,
                paddingTop: 0,
                marginTop: '0.8em',
                marginBottom: '0.8em',
                borderWidth: '0.12em 0.2em 0.12em 0.08em',
                borderRadius: '1.5em'
            },
            [theme.breakpoints.up('md')]: {
                padding: '1.5em',
                paddingBottom: 0,
                paddingTop: 0,
                marginTop: '1em',
                marginBottom: '1em',
                borderWidth: '0.15em 0.3em 0.15em 0.1em',
                borderRadius: '2em'
            },
        },
        contactItemContainerMini: {
            borderColor: 'rgba(15, 113, 174, ' + '1)',
            width: '100%',
            borderStyle: 'solid',
            background: `linear-gradient(90deg, rgba(15, 113, 174, 0.4) 0%, rgba(15, 113, 174, 0) 100%)`,
            alignItems: 'center',
            [theme.breakpoints.up('xs')]: {
                padding: '0.8em',
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: '0.5em',
                marginBottom: '0.5em',
                borderWidth: '0.12em 0.2em 0.12em 0.08em',
                borderRadius: '1.5em'
            },
            [theme.breakpoints.up('sm')]: {
                padding: '1em',
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: '0.8em',
                marginBottom: '0.8em',
                borderWidth: '0.12em 0.2em 0.12em 0.08em',
                borderRadius: '1.5em'
            },
            [theme.breakpoints.up('md')]: {
                padding: '1.5em',
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: '1em',
                marginBottom: '1em',
                borderWidth: '0.15em 0.3em 0.15em 0.1em',
                borderRadius: '2em'
            },
            '&:hover': {
                background: `linear-gradient(90deg, rgba(15, 113, 174, 0.8) 0%, rgba(15, 113, 174, 0.4) 100%)`,
                //boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.5)',
            },
        },
        contactIcon:{
            [theme.breakpoints.up('xs')]: {
                height: '1.5em',
                width: '1.5em'
            },
            [theme.breakpoints.up('sm')]: {
                height: '2em',
                width: '2em'
            },
            [theme.breakpoints.up('md')]: {
                height: '3em',
                width: '3em'
            },
            objectFit: 'contain',
        },
        contactDetailsContainer:{
            alignItems: 'center',
            [theme.breakpoints.up('xs')]: {
                marginLeft: '1em'
            },
            [theme.breakpoints.up('sm')]: {
                marginLeft: '1em'
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: '1em'
            },
        },
        contactTitle:{
            fontWeight: 'bold',
            color: 'white',
            wordWrap: 'breakWord',
            textAlign: 'left',
            marginBottom: 0,
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.6em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.9em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.2em',
            },
        },
        contactSubtitle:{
            wordWrap: 'breakWord',
            textAlign: 'left',
            [theme.breakpoints.up('xs')]: {
                fontSize: '0.5em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.7em',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.05em',
            },
        },
        contactArrowLabel:{
            [theme.breakpoints.up('xs')]: {
                height: '0.8em',
                width: '0.8em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '1em',
                width: '1em',
            },
            [theme.breakpoints.up('md')]: {
                height: '1.5em',
                width: '1.5em',
            },
        },
        sliderContainer:{
            [theme.breakpoints.up('xs')]: {
                paddingLeft: '0.4em',
                paddingRight: '0.4em'
            },
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '0.7em',
                paddingRight: '0.7em'
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: '1em',
                paddingRight: '1em'
            },
        },
        sliderStyle:{
            trackColor: 'red'
        }
    }));

    const finalStyles = useStyles();

    return finalStyles;
}

export default Styles