import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import { Link, withRouter } from "react-router-dom";

import Swal from 'sweetalert2';

import Carousel from 'react-material-ui-carousel';

import Wave from 'react-wavify';

import FlatList from 'flatlist-react';


import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';

import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import GetAppIcon from '@material-ui/icons/GetApp';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';


import Styles from "../components/Styles";
import Header from "../components/Header";
import ProgramItem from "../components/ProgramItem";
import "../components/WavesCSS.css";
//import podcastIcon from "../assets/img/podcastIcon.png";
//import SMIIcon from "../assets/img/smiIcon.png";

var BalsamoDeVida = "https://unionsinfronteras.com/media/BalsamoDeVida.jpg";
var JuventudActiva = "https://unionsinfronteras.com/media/JuventudActiva.jpg";
var EmpoderadaMujer = "https://unionsinfronteras.com/media/EmpoderadaMujer.jpg";
var UnaVezMas = "https://unionsinfronteras.com/media/UnaVezMas.jpg";
var VivenciaDigital = "https://unionsinfronteras.com/media/VivenciaDigital.jpg";
var LaBibliaATuAlcance = "https://unionsinfronteras.com/media/LaBibliaATuAlcance.jpg";
var QueAlguienMeExplique = "https://unionsinfronteras.com/media/QueAlguienMeExplique.jpg";
var UnEncuentroPersonalConDios = "https://unionsinfronteras.com/media/UnEncuentroPersonalConDios.jpg";
var UnRayoDeLuz = "https://unionsinfronteras.com/media/UnRayoDeLuz.jpg";
var SeguimosTrabajando = "https://unionsinfronteras.com/media/SeguimosTrabajando.jpg";

var AllTogether = "https://unionsinfronteras.com/media/AllTogether.jpg";
var playIcon = "https://unionsinfronteras.com/media/playIcon.png";
var pauseIcon = "https://unionsinfronteras.com/media/pauseIcon.png";
var podcastIcon = "https://unionsinfronteras.com/media/podcastIcon.png";
var SMIIcon = "https://unionsinfronteras.com/media/smiIcon.png";
var bannerAppIcon = "https://unionsinfronteras.com/media/bannerApp.jpg";


const getRightResponse = (response) => {
    if(typeof response.errors === 'object'){
        var error = ''
        for (var result in response.errors) {
            error += `${response.errors[result]}\n\n`
        }

        return error
    }else{
        return response.message
    }
}


function ShowOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="up" in={trigger}>
            {children}
        </Slide>
    );
}

const useAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = (isPlaying) => setPlaying(isPlaying);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
};

function getWaveOptions(playing){
    if(playing){
        return {
            height: 8,
            amplitude: 8,
            speed: 0.9,
            points: 2
        }
    }else{
        return {
            height: 5,
            amplitude: 10,
            speed: 0.3,
            points: 2
        }
    }
}

function getBigWaveOptions(playing){
    if(playing){
        return {
            height: 4,
            amplitude: 12,
            speed: 0.9,
            points: 2
        }
    }else{
        return {
            height: 8,
            amplitude: 10,
            speed: 1,
            points: 1
        }
    }
}

function time24to12(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
};

const getDayDate = (day) => {
    switch(day.toLowerCase()){
        case 'lunes':
            return '2020-12-07'
        case 'martes':
            return '2020-12-08'
        case 'miercoles':
            return '2020-12-09'
        case 'jueves':
            return '2020-12-10'
        case 'viernes':
            return '2020-12-11'
        case 'sabado':
            return '2020-12-12'
        case 'domingo':
            return '2020-12-13'
        default:
            return '2020-12-07'
    }
}

const getRealTime = (minutes) => {
    if(minutes < 10)
        return '0' + minutes
    else
        return minutes
}

const showDaysHour = (type, days, hour) => {
    var finalDays = '| ';
    var finalHour = '';

    var horaVenezuela = ''
    var horaLocal = ''

    var today
    var weekday=new Array(7)
    weekday[0] = 'Domingo'
    weekday[1] = 'Lunes'
    weekday[2] = 'Martes'
    weekday[3] = 'Miércoles'
    weekday[4] = 'Jueves'
    weekday[5] = 'Viernes'
    weekday[6] = 'Sábado'

    for (var i = 0; i < days.length; i++) {
        //HORARIO VERANO -5:00 HORARIO INVIERNO -4:00
        today = new Date(getDayDate(days[i]) + 'T' + hour + '-05:00')
        if(i != days.length-1)
            finalDays += weekday[today.getDay()] + ' | '
        else
            finalDays += weekday[today.getDay()] + ' |'
    }

    finalHour = time24to12(getRealTime(today.getHours()) + ':' + getRealTime(today.getMinutes()))
    //var today = new Date(getDayDate(days)'T13:59:00Z')
    //var tomorrow = new Date(this.addDaysToDate(scheduleDate, 1, 'reverse')+'T13:59:00Z')


    //console.log(`HV: ${horaVenezuela} HL: ${horaLocal}\nFD: ${finalDays} FH: ${finalHour}`)
    if(type == 'day'){
        if(days[0] == 'Escríbenos a:')
            return days[0]
        else
            return finalDays
    }else{
        if(days[0] == 'Escríbenos a:')
            return '24 Horas al día'
        else
            return finalHour
    }
}

const formatTextBold = (props) => {
    //console.log('TEXTO A CONVERTIR EN BOLD', props.text)

    //Divide el texto en secciones individuales
    var startBold = props.text.split('(snn)')
    if(startBold.length > 1){
        var endBold = []
        //console.log(`Pos salió esto:\n${JSON.stringify(startBold)}\n${startBold.length}`)
        for (var i = 0; i < startBold.length; i++) {
            endBold.push(
                startBold[i].split('(enn)')
            )
        }
        //console.log(`Pos LUEGO salió esto:\n${JSON.stringify(endBold)}\n${startBold.length}`)
        for (var y = 0; y < endBold.length; y++) {
            if(endBold[y].length > 1){
                //console.log(`Pos vamos a editar esto:\n${JSON.stringify(endBold[y])}\n${endBold[y].length}`)
                endBold[y][0] = (<span key={Math.random(0, 10000000)} style={{fontWeight: 'bold',}}>{endBold[y][0]}</span>)
            }
        }
        return endBold
    }else{
        return props.text
    }
}
const formatingText = (props) => {

    //Divide el texto en secciones individuales
    var startItalic = props.text.split('(skk)')
    //Se genera un array con las divisiones, si es mayor a 1 hacemos algo
    if(startItalic.length > 1){
        var endItalic = []
        //console.log(`Pos salió esto:\n${JSON.stringify(startItalic)}\n${startItalic.length}`)
        //Ahora vamos a dividir las secciones del array para separar la terminación del formato
        for (var i = 0; i < startItalic.length; i++) {
            //Agregamos arrays dentro del array creando una matriz
            endItalic.push(
                //Se genera algo como [["Sin (ekk)"], ["Este sí tiene (ekk)", "Esto fue lo que sobró"], ["Sin (ekk)"]]
                startItalic[i].split('(ekk)')
            )
        }
        //console.log(`Pos LUEGO salió esto:\n${JSON.stringify(endItalic)}\n${startItalic.length}`)
        for (var y = 0; y < endItalic.length; y++) {
            //Si el array de la posición y en la matriz tiene más de un elemento entonces hace algo
            if(endItalic[y].length > 1){
                //console.log(`Pos vamos a editar esto:\n${JSON.stringify(endItalic[y])}\n${endItalic[y].length}`)
                //Reemplazamos el primer elemento del array por el componente con formato
                endItalic[y][0] = (<span key={Math.random(0, 10000000)} style={{fontStyle: 'italic', fontWeight: 'normal'}}>{formatTextBold({text: endItalic[y][0]})}</span>)
                endItalic[y][1] = (<span key={Math.random(0, 10000000)}>{formatTextBold({text: endItalic[y][1]})}</span>)
            }else{
                endItalic[y][0] = (<span key={Math.random(0, 10000000)}>{formatTextBold({text: endItalic[y][0]})}</span>)
            }
        }
        return endItalic
    }else{
        return formatTextBold({text: props.text})
    }
}



function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const getPictureSrc = (link) => {
    if(link.name != null){
        return link.name
    }else{
        return ''
    }
}

const getDataSchedule = (conection, setServerScheduleItems, setRefreshing) => {
    //console.log(`Vamo a obtener lista de programas\n\n${conection}`)
    setRefreshing(true)
    fetch(`${conection}/programa`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'error',
            title: 'Unión Sin Fronteras',
            text: 'Error de conexión del servidor :(\n\nIntenta más tarde',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing(false)
    })
    .then(response => {
        //console.log(`LOG Programas: ${JSON.stringify(response)}`)
        if(response.errors == false){
            setServerScheduleItems(response.data)
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Unión Sin Fronteras',
                text: `${getRightResponse(response)}`,
                confirmButtonText:
                    'Aceptar',
            })
        }
        setRefreshing(false)
    });
}


const getDataWelcomeMessage = (conection, setServerMessages, setRefreshing) => {
    //console.log(`Vamo a obtener lista de mensajes de bienvenida\n\n${conection}`)
    setRefreshing(true)
    fetch(`${conection}/bienvenida`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'error',
            title: 'Unión Sin Fronteras',
            text: 'Error de conexión del servidor :(\n\nIntenta más tarde',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing(false)
    })
    .then(response => {
        //console.log(`LOG Mensajes de bienvenida: ${JSON.stringify(response)}`)
        if(response.errors == false){
            setServerMessages(response.data)
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Unión Sin Fronteras',
                text: `${getRightResponse(response)}`,
                confirmButtonText:
                    'Aceptar',
            })
        }
        setRefreshing(false)
    });
}

const getDataAllPhoto = (conection, setServerAllPhoto, setRefreshing) => {
    //console.log(`Vamo a obtener foto de todos\n\n${conection}`)
    setRefreshing(true)
    fetch(`${conection}/fotoportada`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'error',
            title: 'Unión Sin Fronteras',
            text: 'Error de conexión del servidor :(\n\nIntenta más tarde',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing(false)
    })
    .then(response => {
        //console.log(`LOG AllPhoto: ${JSON.stringify(response)}`)
        if(response.errors == false){
            setServerAllPhoto(response.data)
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Unión Sin Fronteras',
                text: `${getRightResponse(response)}`,
                confirmButtonText:
                    'Aceptar',
            })
        }
        setRefreshing(false)
    });
}

function Home(props) {

    //const conection = 'https://angel140496.com/usfa/public/api';
    const conection = 'https://unionsinfronteras.com/usfa/public/api';

    const [welcomeMessages, setWelcomeMessages] = useState([
        {
            id: '1',
            title: 'Si estamos creciendo, siempre vamos a estar fuera de nuestra zona de confort. (skk)John Maxwell(ekk)'
        },
        {
            id: '2',
            title: 'Intenta, falla, piensa y vuelve a intentar. (skk)Alex Dey(ekk)'
        },
        {
            id: '3',
            title: 'La sinceridad hace a la menor de las personas más valiosa que el hipócrita más talentoso. (skk)Charles Spurgeon(ekk)'
        },
        {
            id: '4',
            title: 'Tengo tres perros peligrosos: la ingratitud, la soberbia y la envidia. Cuando muerden dejan una herida profunda. (skk)Martín Lutero(ekk)'
        },
        {
            id: '5',
            title: 'No puedo parar mientras existan vidas que salvar. (skk)Edith Cavell(ekk)'
        },
        {
            id: '6',
            title: 'Si has llegado a una situación difícil por seguir la voluntad de Dios no temas las consecuencias. (skk)Arnaldo Sánchez(ekk)'
        },
        {
            id: '7',
            title: 'Ama la verdad, vive la verdad, predica la verdad, defiende la verdad. Porque el que no habla la verdad, traiciona la verdad. (skk)Juan Huss(ekk)'
        },
        {
            id: '8',
            title: 'El tiempo es más valioso que el dinero, porque el tiempo es insustituible. (skk)John Maxwell(ekk)'
        },
        {
            id: '9',
            title: 'Cuando no podemos seguir soñando, morimos. (skk)Emma Goldman(ekk)'
        },
        {
            id: '10',
            title: 'Aunque el final del mundo sea mañana, hoy plantaré manzanos en mi huerto. (skk)Martín Lutero(ekk)'
        },
        {
            id: '11',
            title: 'No existen las distancias cuando se tiene un motivo. (skk)Jane Austen(ekk)'
        },
    ]);

    const [scheduleItems, setScheduleItems] = useState(
        [
            {
                id: 1,
                day: ['lunes', 'miercoles', 'sabado'],
                hour: '10:00',
                title: 'Vivencia Digital',
                icon: VivenciaDigital,
                nameVoice: [
                    {
                        id: '1',
                        name: 'Mileydis Soublette',
                        facebook: 'https://www.facebook.com/mily.soublette/'
                    },
                ],
                description: 'Cuando lo virtual supera lo real.'
            },
            {
                id: 2,
                day: ['lunes'],
                hour: '14:00',
                title: 'Empoderada Mujer',
                icon: EmpoderadaMujer,
                nameVoice: [
                    {
                        id: '1',
                        name: 'Rosmary Luzardo',
                        facebook: 'https://www.facebook.com/rosmary.luzardo/'
                    },
                    {
                        id: '2',
                        name: 'Ysabel Luzardo',
                        facebook: 'https://www.facebook.com/profile.php?id=100009353048478/'
                    },
                ],
                description: 'Amante y visionaria.'
            },
            {
                id: 3,
                day: ['lunes', 'jueves'],
                hour: '16:00',
                title: 'Juventud Activa',
                icon: JuventudActiva,
                nameVoice: [
                    {
                        id: '1',
                        name: 'Keyla Pérez',
                        facebook: 'https://www.facebook.com/keyla.perez.1253/'
                    },
                ],
                description: 'Un concepto joven, fresco y dinámico, con información de interés para toda la juventud.'
            },
            {
                id: 4,
                day: ['martes'],
                hour: '09:00',
                title: 'Bálsamo de Vida',
                icon: BalsamoDeVida,
                nameVoice: [
                    {
                        id: '1',
                        name: 'Denys Castillo',
                        facebook: 'https://www.facebook.com/denys.castillo.5/'
                    },
                ],
                description: 'Una fragancia que eleva tu espíritu y llena tu ser. Enseñanzas de las Sagradas Escrituras.'
            },
            {
                id: 5,
                day: ['martes'],
                hour: '10:00',
                title: 'Una Vez Más',
                icon: UnaVezMas,
                nameVoice: [
                    {
                        id: '1',
                        name: 'María Abreu',
                        facebook: 'https://www.facebook.com/abreubrahim/'
                    },
                ],
                description: 'Abriendo los Corazones. Un programa que contribuye al desarrollo espiritual.'
            },
            {
                id: 7,
                day: ['jueves'],
                hour: '09:00',
                title: 'Un Encuentro Personal con Dios',
                icon: UnEncuentroPersonalConDios,
                nameVoice: [
                    {
                        id: '1',
                        name: 'Evelyn Carmona',
                        facebook: 'https://www.facebook.com/evelynhaydee.carmonabecerra/'
                    },
                ],
                description: 'Profundizando las Escrituras. Un mensaje de reconciliación, amor y unidad para todos.'
            },
            {
                id: 8,
                day: ['jueves'],
                hour: '09:00',
                title: 'La Biblia a tu Alcance',
                icon: LaBibliaATuAlcance,
                nameVoice: [
                    {
                        id: '1',
                        name: 'Pr. Alpidio Laya',
                        facebook: ''
                    },
                ],
                description: 'Un espacio para nutrir nuestra vida espiritual.'
            },
            {
                id: 9,
                day: ['miercoles'],
                hour: '14:00',
                title: '¡QUE ALGUIEN ME EXPLIQUE!',
                icon: QueAlguienMeExplique,
                nameVoice: [
                    {
                        id: '1',
                        name: 'Ana Rojas',
                        facebook: 'https://www.facebook.com/profile.php?id=100006963700732/'
                    },
                ],
                description: 'Buscando alternativas para dar la mejor respuesta a tus inquietudes.'
            },
            {
                id: 10,
                day: ['Escríbenos a:'],
                hour: 'unionsinfronteras@unionsinfronteras.com',
                title: 'Seguimos trabajando para ti en este 2021',
                icon: SeguimosTrabajando,
                nameVoice: [
                    {
                        id: '1',
                        name: 'Unión Sin Fronteras Radio',
                        facebook: 'https://www.facebook.com/FundacionUnionSinFronteras/'
                    },
                ],
                description: 'Seguimos trabajando para ti en este (snn)2021(enn)\n\n(skk)(snn)¡Acompáñanos!(enn)(ekk)\n\n(skk)** Próximamente subiremos en este medio el programa de la radio actualizado en tiempo real. **(ekk)'
            },
        ]
    );



    const [urls, setUrls] = useState([
        'https://unionsinfronteras.com/p1.mp3',
        'https://unionsinfronteras.com/p2.mp3',
        'https://unionsinfronteras.com/p3.mp3',
        'https://unionsinfronteras.com/p4.mp3',
        'https://unionsinfronteras.com/p5.mp3',
    ]);

    
    const [serverScheduleItems, setServerScheduleItems] = useState([]);
    const [serverPodcastItems, setServerPodcastItems] = useState([]);
    const [serverWelcomeMessageItems, setServerWelcomeMessageItems] = useState([]);
    const [serverAllPhoto, setServerAllPhoto] = useState('');

    const [refreshing, setRefreshing] = useState(false);


    useEffect(() => {
        getDataSchedule(conection, setServerScheduleItems, setRefreshing)
        getDataWelcomeMessage(conection, setServerWelcomeMessageItems, setRefreshing)
        getDataAllPhoto(conection, setServerAllPhoto, setRefreshing)
    }, [])
    


    const { playIconStyle5, wzWaitingContianer, wzTextHuge, speakersIconStyleContainer, waveFormRight, waveFormLeft, closeBtn, speakersIconContainer, playIconStyle3, speakersIconBgStyle, closeBtnContainer, searchPodcastInputContainer, searchPodcastInput, searchPodcastBtn, podcastModalContainer, speakersDetailsContainer, playIconStyle4, allContainerFilled, footerIconStyle, realFooterContainer, podcastTitle, wzDetailsProgram, paperTitle, podcastSpeakers, podcastDate, podcastContainer, playIconPodcastContainer, paperPodcast, allTogetherIconBigStyle, playIconStyle2, allTogetherIconBigContainer, waveForm, gridPlayerContainer, hourPlayer, speakersPlayer, titlePlayer, allPlayerContainer, playerContainer, playIconContainer, playIconStyle, titleTextContainer, speakersIconStyle, speakersImageContainer, paper, paperMessage, footerContainer, carouselStyle, footer, footerBtn, popoverStyle, wzSubtitleFading, welcomeMessageContainer, wzText } = Styles();
 
    



    const [anchorElHome, setAnchorElHome] = useState(null);

    const handlePopoverOpenHome = (event) => {
        setAnchorElHome(event.currentTarget);
    };

    const handlePopoverCloseHome= () => {
        setAnchorElHome(null);
    };

    const openHome = Boolean(anchorElHome);

    const [anchorElAboutUs, setAnchorElAboutUs] = useState(null);

    const handlePopoverOpenAboutUs = (event) => {
        setAnchorElAboutUs(event.currentTarget);
    };

    const handlePopoverCloseAboutUs= () => {
        setAnchorElAboutUs(null);
    };

    const openAboutUs = Boolean(anchorElAboutUs);

    const [anchorElAndroidLink, setAnchorElAndroidLink] = useState(null);

    const handlePopoverOpenAndroidLink = (event) => {
        setAnchorElAndroidLink(event.currentTarget);
    };

    const handlePopoverCloseAndroidLink= () => {
        setAnchorElAndroidLink(null);
    };

    const openAndroidLink = Boolean(anchorElAndroidLink);

    const [anchorEliOSLink, setAnchorEliOSLink] = useState(null);

    const handlePopoverOpeniOSLink = (event) => {
        setAnchorEliOSLink(event.currentTarget);
    };

    const handlePopoverCloseiOSLink= () => {
        setAnchorEliOSLink(null);
    };

    const openiOSLink = Boolean(anchorEliOSLink);


    const [anchorElPodcasts, setAnchorElPodcasts] = useState(null);

    const handlePopoverOpenPodcasts = (event) => {
        setAnchorElPodcasts(event.currentTarget);
    };

    const handlePopoverClosePodcasts= () => {
        setAnchorElPodcasts(null);
    };

    const openPodcasts = Boolean(anchorElPodcasts);


    const [anchorElSMI, setAnchorElSMI] = useState(null);

    const handlePopoverOpenSMI = (event) => {
        setAnchorElSMI(event.currentTarget);
    };

    const handlePopoverCloseSMI= () => {
        setAnchorElSMI(null);
    };

    const openSMI = Boolean(anchorElSMI);



    const [wobble, setWobble] = React.useState(0);



    





    const [playing, togglePlayRadio] = useAudio('https://s4.yesstreaming.net:17095/stream');

    const [currentTime, setCurrentTime] = useState(0);

    const [duration, setDuration] = useState(0);

    return (
        <Grid style={{ position: 'relative', background: 'linear-gradient(135deg, rgba(3,52,75,1) 0%, rgba(6,152,218,1) 100%)',}}>
            <Wave
                fill="url(#gradient2)"
                paused={false}
                options={getBigWaveOptions(playing)}
                className={waveFormRight}
            >
                <defs>
                    <linearGradient id="gradient2" gradientTransform="rotate(180)">
                        <stop offset="20%"  stopColor="rgba(255, 255, 255, 0.11)" />
                        <stop offset="80%" stopColor="rgba(6, 152, 218, 0.5)" />
                    </linearGradient>
                </defs>
            </Wave>
            <Header bg={'white'} playing={playing}/>
            <Container className={allContainerFilled}>
                <HideOnScroll {...props}>
                    <Grid container spacing={2} className={welcomeMessageContainer}>
                        <Grid item xs={12}>
                            <Paper className={paperMessage}>
                                <Carousel
                                    className={carouselStyle}
                                    autoPlay={true}
                                    animation={'fade'}
                                    timeout={500}
                                    interval={6000}
                                    indicators={false}
                                    navButtonsAlwaysVisible={false}
                                    navButtonsAlwaysInvisible={true}
                                    next={ (next, active) => {/*console.log(`we left ${active}, and are now at ${next}`)*/} }
                                    prev={ (prev, active) => {/*console.log(`we left ${active}, and are now at ${prev}`)*/} }
                                >
                                    {serverWelcomeMessageItems.map( (item, i) => <p key={i} className={wzSubtitleFading} style={{opacity: 1}}><span style={{fontWeight: 'normal',}}>Palabra de hoy: </span>{formatingText({text: item.title})}</p> )}
                                </Carousel>
                            </Paper>
                        </Grid>
                    </Grid>
                </HideOnScroll>
                <Grid container item xs={12} className={allTogetherIconBigContainer}>
                    <Grid className={allPlayerContainer} item xs={12} sm={8} md={9}>
                        <Carousel
                            className={carouselStyle}
                            autoPlay={true}
                            animation={'slide'}
                            timeout={600}
                            interval={10000}
                            indicators={false}
                            navButtonsAlwaysVisible={false}
                            next={ (next, active) => {/*console.log(`we left ${active}, and are now at ${next}`)*/} }
                            prev={ (prev, active) => {/*console.log(`we left ${active}, and are now at ${prev}`)*/} }
                        >
                            {serverScheduleItems.map( (item, i) => <ProgramItem key={i} position={i} item={item} totalItems={serverScheduleItems.length} /> )}
                        </Carousel>
                    </Grid>
                    {/*Pos aquí va el reproductor*/}
                    <Grid item xs={12} sm={4} md={3} className={gridPlayerContainer}>
                        <Wave
                            fill="url(#gradient)"
                            paused={false}
                            options={getWaveOptions(playing)}
                            className={waveForm}
                        >
                            <defs>
                                <linearGradient id="gradient" gradientTransform="rotate(90)">
                                    <stop offset="20%"  stopColor="rgba(140,200,50,1)" />
                                    <stop offset="80%" stopColor="rgba(165,236,59,1)" />
                                </linearGradient>
                            </defs>
                        </Wave>
                        <Grid className={speakersDetailsContainer}>
                            <Grid className={speakersIconContainer} style={{background: 'rgba(3,52,75,1)'}}>
                                <img className={speakersIconBgStyle} src={bannerAppIcon} alt="Logo"/>
                                <Grid className={speakersIconStyleContainer}>
                                    <img className={speakersIconStyle} src={bannerAppIcon} alt="Logo"/>
                                </Grid>
                            </Grid>
                            <div className={titleTextContainer}>
                                <p className={titlePlayer}>{scheduleItems[scheduleItems.length-1].title}</p>
                                <p className={speakersPlayer}>{scheduleItems[scheduleItems.length-1].nameVoice[0].name}</p>
                                <p className={hourPlayer} style={{color: 'white'}}>{showDaysHour('hour', scheduleItems[scheduleItems.length-1].day, scheduleItems[scheduleItems.length-1].hour)}</p>
                            </div>
                        </Grid>
                        <Grid item className={playerContainer}>
                            <Button onClick={() => {
                                if(playing)
                                    togglePlayRadio(false)
                                else
                                    togglePlayRadio(true)
                                //togglePlayingAudio('http://107.182.231.73:7095/live.ogg')
                            }} className={playIconContainer}>
                                {playing ? <img className={playIconStyle} src={pauseIcon} alt="Logo"/> : <img className={playIconStyle} src={playIcon} alt="Logo"/>}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container className={allTogetherIconBigContainer}>
                    <Grid item xs={1} sm={1} md={2}></Grid>
                    <Grid item xs={10} sm={10} md={8}>
                        <img className={allTogetherIconBigStyle} src={getPictureSrc(serverAllPhoto)} alt="Foto de todos"/>
                    </Grid>
                    <Grid item xs={1} sm={1} md={2}></Grid>
                </Grid>
                <Grid container className={realFooterContainer}>
                    <Grid item xs={12} sm={6} style={{paddingRight: '1em'}} className={allTogetherIconBigContainer}>
                        <p className={wzDetailsProgram}>+RADIOS</p>
                        <a style={{'textDecoration': 'none'}} href="https://hosannaradiointernacional.com/" target="_blank"><p className={wzDetailsProgram} style={{opacity: 1, fontWeight: 'normal'}}>Hosanna International Radio (EE.UU.)</p></a>
                        <a style={{'textDecoration': 'none'}} href="https://imaginaradio.net/" target="_blank"><p className={wzDetailsProgram} style={{opacity: 1, fontWeight: 'normal'}}>Imagina Radio (México)</p></a>
                    </Grid>
                    <Grid item xs={12} sm={6} className={allTogetherIconBigContainer}>
                        <p className={wzDetailsProgram}>NUESTRO OBJETIVO</p>
                        <p className={wzDetailsProgram} style={{opacity: 1, fontWeight: 'normal', textAlign: 'justify', textJustify: 'inter-word',}}>Promover el desarrollo integral de la sociedad, consolidando su formación por medio de mensajes educativos, culturales y entretenimiento sano.</p>
                    </Grid>
                    <Grid item xs={12}>
                        <p className={wzDetailsProgram} style={{opacity: 1, fontWeight: 'normal', textAlign: 'center'}}>© 2010-2021 unionsinfronteras.com ¡Bendiciones!</p>
                    </Grid>
                </Grid>



                <Grid container>
                    <Grid item xs={12} className={footerContainer}>
                        <BottomNavigation
                            className={footer}
                        >
                            <Button
                                className={footerBtn}
                                variant="contained"
                                onMouseEnter={handlePopoverOpeniOSLink}
                                onMouseLeave={handlePopoverCloseiOSLink}
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://bit.ly/usfappios', "_blank")
                                }}
                            >
                                <AppleIcon className={footerIconStyle} style={{color: 'rgba(3,52,75,1)'}}/>
                            </Button>
                            <Popover
                                id="mouse-over-popover"
                                className={popoverStyle}
                                classes={{
                                    paper: paper,
                                }}
                                open={openiOSLink}
                                anchorEl={anchorEliOSLink}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverCloseiOSLink}
                                disableRestoreFocus
                            >
                                <Typography>Aplicación para iOS</Typography>
                            </Popover>
                            <Button
                                className={footerBtn}
                                variant="contained"
                                onMouseEnter={handlePopoverOpenPodcasts}
                                onMouseLeave={handlePopoverClosePodcasts}
                                onClick={() => {
                                    props.history.push({
                                        pathname: "/Podcasts",
                                        //search: "?sort=name",
                                        //hash: "#the-hash",
                                        state: {
                                            conection: conection
                                            //adminType: response.data
                                        }
                                    });
                                    togglePlayRadio(false)
                                }}
                            >
                                <img src={podcastIcon} className={playIconStyle2}/>
                            </Button>
                            <Popover
                                id="mouse-over-popover"
                                className={popoverStyle}
                                classes={{
                                    paper: paper,
                                }}
                                open={openPodcasts}
                                anchorEl={anchorElPodcasts}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverClosePodcasts}
                                disableRestoreFocus
                            >
                                <Typography>Podcasts</Typography>
                            </Popover>
                            <Button
                                className={footerBtn}
                                variant="contained"
                                onMouseEnter={handlePopoverOpenHome}
                                onMouseLeave={handlePopoverCloseHome}
                            >
                                <HomeIcon className={footerIconStyle} style={{color: 'rgba(255, 255, 255, 1)'}}/>
                            </Button>
                            <Popover
                                id="mouse-over-popover"
                                className={popoverStyle}
                                classes={{
                                    paper: paper,
                                }}
                                open={openHome}
                                anchorEl={anchorElHome}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverCloseHome}
                                disableRestoreFocus
                            >
                                <Typography>Inicio</Typography>
                            </Popover>
                            <Button
                                className={footerBtn}
                                variant="contained"
                                onMouseEnter={handlePopoverOpenAboutUs}
                                onMouseLeave={handlePopoverCloseAboutUs}
                                onClick={() => {
                                    props.history.push({
                                        pathname: "/Nosotros",
                                        //search: "?sort=name",
                                        //hash: "#the-hash",
                                        state: {
                                            conection: conection
                                            //adminType: response.data
                                        }
                                    });
                                }}
                            >
                                <PeopleAltIcon className={footerIconStyle} style={{color: 'rgba(255, 255, 255, 0.4)'}}/>
                            </Button>
                            <Popover
                                id="mouse-over-popover"
                                className={popoverStyle}
                                classes={{
                                    paper: paper,
                                }}
                                open={openAboutUs}
                                anchorEl={anchorElAboutUs}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverCloseAboutUs}
                                disableRestoreFocus
                            >
                                <Typography>Nosotros</Typography>
                            </Popover>
                            <Button
                                className={footerBtn}
                                variant="contained"
                                onMouseEnter={handlePopoverOpenSMI}
                                onMouseLeave={handlePopoverCloseSMI}
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://asd1844.org.ve/', '_blank');
                                }}
                            >
                                <img src={SMIIcon} className={playIconStyle4}/>
                            </Button>
                            <Popover
                                id="mouse-over-popover"
                                className={popoverStyle}
                                classes={{
                                    paper: paper,
                                }}
                                open={openSMI}
                                anchorEl={anchorElSMI}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverCloseSMI}
                                disableRestoreFocus
                            >
                                <Typography>SMI Venezuela</Typography>
                            </Popover>
                            <Button
                                className={footerBtn}
                                variant="contained"
                                onMouseEnter={handlePopoverOpenAndroidLink}
                                onMouseLeave={handlePopoverCloseAndroidLink}
                                onClick={() => {
                                    window.open('https://bit.ly/usfappandroid', "_blank")
                                }}
                            >
                                <AndroidIcon className={footerIconStyle} style={{color: 'rgba(3,52,75,1)'}}/>
                            </Button>
                            <Popover
                                id="mouse-over-popover"
                                className={popoverStyle}
                                classes={{
                                    paper: paper,
                                }}
                                open={openAndroidLink}
                                anchorEl={anchorElAndroidLink}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                onClose={handlePopoverCloseAndroidLink}
                                disableRestoreFocus
                            >
                                <Typography>Aplicación para Android</Typography>
                            </Popover>
                        </BottomNavigation>
                    </Grid>
                </Grid>
            </Container>
            {
                refreshing ?
                    <Grid className={wzWaitingContianer}>
                        <p className={wzTextHuge} style={{color: 'white', fontWeight: 'bold', textAlign: 'center'}}>Cargando...</p>
                    </Grid>
                :
                    null
            }
        </Grid>
    );
}

export default withRouter(Home);